export const hourDistribution = {
  0: 0.0091,
  1: 0.0049,
  2: 0.0035,
  3: 0.0029,
  4: 0.0034,
  5: 0.0067,
  6: 0.0153,
  7: 0.0352,
  8: 0.0577,
  9: 0.0751,
  10: 0.0847,
  11: 0.0782,
  12: 0.0709,
  13: 0.0599,
  14: 0.0553,
  15: 0.0555,
  16: 0.057,
  17: 0.0547,
  18: 0.0517,
  19: 0.056,
  20: 0.0536,
  21: 0.047,
  22: 0.0384,
  23: 0.0231,
};
