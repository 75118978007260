import { Box, Center, Flex, Stack } from '@chakra-ui/react';

const LoginView = ({ children }: JSX.ElementChildrenAttribute) => {
  return (
    <Box minH='100vh' w='100%' bg='#002529'>
      <Center w='100%' h='100vh'>
        <Box maxW='500px' w='100%' maxH='500px' h='100%' bg='#ffffff' rounded='md' boxShadow='xl' p={3}>
          <Flex align={'center'} justify={'center'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
              {children}
            </Stack>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
};

export default LoginView;
