import { GlobalState } from '../../interface/redux/globalState';
import { AuthProps } from '../../interface/auth/Auth';
import { LoginProps } from '../../interface/auth/Login';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../services/authSerivce';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { toast } from 'react-toastify';

const initialState: GlobalState = {
  entities: {},
  status: fetchStatuses.idle,
  error: false,
};

export const authenticate = createAsyncThunk('auth', async (): Promise<AuthProps> => {
  const authResponse = await AuthService.authenticate();
  return authResponse;
});

export const login = createAsyncThunk('auth/login', async (loginData: LoginProps): Promise<AuthProps> => {
  const authResponse = await AuthService.login(loginData);
  return authResponse;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

export const resetPasswordLink = createAsyncThunk(
  'auth/resetPasswordLink',
  async (email: string): Promise<void> => {
    await AuthService.sendResetPasswordLink(email);
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({
    id,
    passwords,
  }: {
    id: string;
    passwords: {
      newPassword: string;
      confirmationPassword: string;
    };
  }): Promise<void> => {
    await AuthService.resetPassword(id, passwords);
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.status = fetchStatuses.idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authenticate.fulfilled, (state, action) => {
        state.entities = action.payload;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.entities = action.payload;
        toast.success('Zalogowano');
      })
      .addCase(login.rejected, (state, action) => {
        state.status = fetchStatuses.failed;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.entities = {};
        toast.success('Wylogowano');
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = fetchStatuses.failed;
      })
      .addCase(resetPasswordLink.fulfilled, (state, action) => {
        state.status = fetchStatuses.succeeded;
        toast.success(`Nowe hasło zostało wysłane na adres e-mail`);
      })
      .addCase(resetPasswordLink.rejected, (state, action) => {
        state.status = fetchStatuses.failed;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = fetchStatuses.succeeded;
        toast.success('Hasło zostało pomyślnie zmienione');
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = fetchStatuses.failed;
      });
  },
});

export const selectAuth = (state: any) => state.auth;
export const { clearState } = authSlice.actions;

export default authSlice.reducer;
