import { AuthProps } from './../interface/auth/Auth';
import api from './api';
import { LoginProps } from '../interface/auth/Login';

interface IResetPassword {
  newPassword: string;
  confirmationPassword: string;
}

class AuthService {
  static async login(loginData: LoginProps): Promise<AuthProps> {
    const res = await api.post(`/auth/login`, loginData);
    return res.data;
  }

  static async authenticate(): Promise<AuthProps> {
    const res = await api.get(`/auth`);
    return res.data;
  }

  static async logout(): Promise<void> {
    await api.post(`/auth/logout`);
  }

  static async sendResetPasswordLink(email: string) {
    await api.post(`/auth/reset-password-link`, {
      email,
    });
  }

  static async resetPassword(token: string, passwords: IResetPassword) {
    await api.post(`/auth/reset-password/${token}`, passwords);
  }
}

export default AuthService;
