import {
  Flex,
  FormLabel,
  FormControl,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCampaign, selectCampaign } from '../campaign/campaignSlice';
import { clearState, createEcho, selectEcho } from './echoSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { fetchSettings, selectSettings } from '../settings/settingsSlice';

const AddEcho: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [values, setValues] = useState('');
  const { entities: campaignValues } = useSelector(selectCampaign);
  const { entities: settings, status: settingsStatus } = useSelector(selectSettings);
  const { status } = useSelector(selectEcho);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settingsStatus.fetchSettings && settingsStatus.fetchSettings === fetchStatuses.succeeded) {
      setValues(settings.powerDistribution.join('\n'));
    }
  }, [settingsStatus, settings]);

  useEffect(() => {
    if (params.mailingId) {
      dispatch(fetchSingleCampaign(params.mailingId!));
    } else {
      navigate('/');
      toast.error('Kampania nie istnieje');
    }
  }, [navigate, params, dispatch]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    const powerDistribution = values.split('\n').map((item: string) => +item);
    const echoData = {
      powerDistribution: powerDistribution,
      campaign: campaignValues!._id!,
      scriptId: campaignValues!.script!._id!,
    };

    dispatch(createEcho(echoData));
  };

  useEffect(() => {
    if (status.createEcho === fetchStatuses.succeeded) {
      dispatch(clearState());
      navigate('/');
    }
  }, [status, navigate, dispatch]);

  return (
    <Flex
      bg='#ffffff'
      direction={'column'}
      alignItems={'center'}
      rounded='lg'
      boxShadow='xl'
      overflow={'auto'}
      p={6}
      pb={10}
      w='80%'
      h='100%'
    >
      <Heading size={'md'} textTransform='uppercase'>
        dodawanie echa
      </Heading>
      <S.Form onSubmit={(e) => onSubmit(e)}>
        <Flex direction='column' flex={1} overflow='auto' gap='20px' maxW='900px' w='100%' p={6} pr={10}>
          <Text size={'sm'} textAlign='center'>
            Na podstawie: {campaignValues?.calendarName}
          </Text>
          <HStack alignItems={'flex-start'} gap={4}>
            <FormControl>
              <FormLabel>Rozkład mocy</FormLabel>
              <Textarea
                value={values}
                name='powerDistribution'
                id='powerDistribution'
                onChange={(val) => setValues(val.target.value)}
                required
              />
            </FormControl>
          </HStack>
          <Text textAlign='center'>
            Każda liczba oznacza kolejny dzień, jaki procent orginału ma być w echu. Liczby są rodzielone
            znakiem enter.
          </Text>
          <Flex justifyContent='flex-end'>
            <Flex maxW='500px' w='100%' justifyContent={'flex-end'}>
              <ButtonGroup spacing={6}>
                <Button colorScheme='red' onClick={() => navigate('/echos')}>
                  Wróć
                </Button>
                <Button colorScheme='teal' type='submit'>
                  Dodaj
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>
        </Flex>
      </S.Form>
    </Flex>
  );
};

export default AddEcho;
