import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Flex,
  Text,
  Heading,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import FormatDate from '../../utils/formatDate';
import { clearState, fetchScriptLogs, selectScript } from './scriptSlice';
import { ScriptLogsProps } from '../../interface/script/ScriptLogs';

const ScriptLogs: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { entities, status } = useSelector(selectScript);
  const [logs, setLogs] = useState<{
    logs: [];
    idObject: {
      type: string;
      id: string;
    };
  }>({ logs: [], idObject: { type: '', id: '' } });

  useEffect(() => {
    if (!params.id || !params.id) return;
    dispatch(fetchScriptLogs(params.id!));
  }, [params, dispatch]);

  useEffect(() => {
    if (status.fetchScriptLogs === fetchStatuses.succeeded) {
      setLogs(entities);
      dispatch(clearState());
    }
  }, [status, dispatch, entities]);

  return (
    <Box
      w='100%'
      h='100%'
      bg={'whiteAlpha.900'}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      rounded='md'
      boxShadow='xl'
      p={3}
      overflow='auto'
    >
      {!Object.keys(logs.logs).length || status.fetchScriptLogs === fetchStatuses.loading ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <Flex direction='column'>
          <Heading size={'md'} textTransform='uppercase' textAlign='center' my={3}>
            Logi dla skryptu kampanii
          </Heading>
          <ButtonGroup position={'absolute'} top={'36px'} right={'56px'}>
            {logs.idObject && (
              <Button
                size={'sm'}
                colorScheme={'yellow'}
                onClick={() => {
                  logs.idObject.type === 'campaign'
                    ? navigate(`/campaigns/${logs.idObject.id}`)
                    : navigate(`/echos/${logs.idObject.id}`);
                }}
              >
                widok szczegółowy
              </Button>
            )}
          </ButtonGroup>
          <Table variant='striped' colorScheme='blackAlpha' size='sm' mt='2rem'>
            <Thead>
              <Tr>
                <Th>Data</Th>
                <Th>IP</Th>
                <Th>Crawler</Th>
                <Th>Akcja</Th>
                <Th>Kod HTTP</Th>
                <Th>Domena</Th>
                <Th>Urządzenie</Th>
                <Th>Opis błędu</Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs &&
                logs.logs.length > 0 &&
                logs.logs.map((item: ScriptLogsProps, idx: number) => {
                  return (
                    <Tr key={idx}>
                      <Td>
                        <Text fontSize='lg'>{item.date ? FormatDate(item.date, ' ') : ''}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.ip ?? ''}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.crawler ?? ''}</Text>{' '}
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.action ?? ''}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.httpCode ?? ''}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg' maxW='16vw'>
                          {item.domain ?? ''}
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg' maxW='16vw'>
                          {item.deviceType ?? ''}
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg' maxW='16vw'>
                          {item.errorDescription ?? ''}
                        </Text>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Flex>
      )}
    </Box>
  );
};

export default ScriptLogs;
