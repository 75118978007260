import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { SettingsProps } from '../../interface/settings/Settings';
import SettingsService from '../../services/settingsService';
import { toast } from 'react-toastify';

const initialState: GlobalState = {
  entities: {},
  status: {},
  error: false,
};

export const fetchSettings = createAsyncThunk('settings/fetchSettings', async (): Promise<SettingsProps> => {
  const settings = await SettingsService.getSettings();
  return settings;
});

export const updateSettings = createAsyncThunk(
  'settings/updateSettings',
  async (settings: SettingsProps): Promise<SettingsProps> => {
    const newSettings = await SettingsService.updateSettings(settings);
    return newSettings;
  }
);

export const restartCrawlers = createAsyncThunk('settings/restartCrawlers', async (): Promise<void> => {
  await SettingsService.restartCrawlers();
});

export const clearQueues = createAsyncThunk('settings/clearQueues', async (): Promise<void> => {
  await SettingsService.clearQueues();
});

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.status = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSettings.pending, (state, action) => {
        state.status = { ...state.status, fetchSettings: fetchStatuses.loading };
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = { ...state.status, fetchSettings: fetchStatuses.succeeded };
        state.entities = action.payload;
      })
      .addCase(updateSettings.pending, (state, action) => {
        state.status = { ...state.status, updateSettings: fetchStatuses.loading };
        toast.success('Zaktualizowano globalne ustawienia.');
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.status = { ...state.status, updateSettings: fetchStatuses.succeeded };
        state.entities = action.payload;
      })
      .addCase(restartCrawlers.pending, (state, action) => {
        state.status = { ...state.status, restartCrawlers: fetchStatuses.loading };
      })
      .addCase(restartCrawlers.fulfilled, (state, action) => {
        state.status = { ...state.status, restartCrawlers: fetchStatuses.succeeded };
      })
      .addCase(clearQueues.pending, (state, action) => {
        state.status = { ...state.status, clearQueues: fetchStatuses.loading };
      })
      .addCase(clearQueues.fulfilled, (state, action) => {
        state.status = { ...state.status, clearQueues: fetchStatuses.succeeded };
      });
  },
});

export const selectSettings = (state: any) => state.settings;
export const { clearState } = settingsSlice.actions;

export default settingsSlice.reducer;
