import api from './api';

class GatewaysService {
  static async getGateways(): Promise<string[]> {
    const res = await api.get('/gateways');
    return res.data;
  }

  static async updateGateways(gateways: string[]): Promise<string[]> {
    const res = await api.post('/gateways', { gateways });
    return res.data;
  }
}

export default GatewaysService;
