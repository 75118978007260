import { Flex, Spinner } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../features/auth/authSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import LoginView from './LoginView';
import MainView from './MainView';

const MainLayout = ({ children }: JSX.ElementChildrenAttribute) => {
  const { entities: auth, status } = useSelector(selectAuth);

  return (
    <>
      {status === fetchStatuses.loading ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : auth && !auth.email ? (
        <LoginView>{children}</LoginView>
      ) : (
        <MainView>{children}</MainView>
      )}
    </>
  );
};

export default MainLayout;
