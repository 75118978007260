import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { toast } from 'react-toastify';
import GatewaysService from '../../services/gatewaysService';

const initialState: GlobalState = {
  entities: {},
  status: {},
  error: false,
};

export const fetchGateways = createAsyncThunk('gateways/fetchGateways', async (): Promise<string[]> => {
  const gateways = await GatewaysService.getGateways();
  return gateways;
});

export const updateGateways = createAsyncThunk(
  'gateways/updateGateways',
  async (gateways: string[]): Promise<string[]> => {
    const newGateways = await GatewaysService.updateGateways(gateways);
    return newGateways;
  }
);

export const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.status = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGateways.pending, (state, action) => {
        state.status = { ...state.status, fetchGateways: fetchStatuses.loading };
      })
      .addCase(fetchGateways.fulfilled, (state, action) => {
        state.status = { ...state.status, fetchGateways: fetchStatuses.succeeded };
        state.entities = action.payload;
      })
      .addCase(updateGateways.pending, (state, action) => {
        state.status = { ...state.status, updateGateways: fetchStatuses.loading };
      })
      .addCase(updateGateways.fulfilled, (state, action) => {
        state.status = { ...state.status, updateGateways: fetchStatuses.succeeded };
        state.entities = action.payload;
        toast.success('Zaktualizowano globalne ustawienia.');
      });
  },
});

export const selectGateways = (state: any) => state.gateways;
export const { clearState } = gatewaysSlice.actions;

export default gatewaysSlice.reducer;
