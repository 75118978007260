import moment from 'moment';
import { hourDistribution } from '../static/hourDistribution';

const createClickDistributions = (startHour: number) => {
  const previousDistributions = new Array(startHour).fill(0);

  let nextClickDistributions = Object.values(hourDistribution).slice(startHour);

  const nextClickDistributionSum = nextClickDistributions.reduce((acc, curr) => acc + curr);

  nextClickDistributions = nextClickDistributions.map((distribution) => {
    return distribution / nextClickDistributionSum;
  });

  return {
    previousDistributions,
    nextClickDistributions,
  };
};

const splitStartingClickDistributionRest = (nextClickDistributions: any, startMinute: number) => {
  const startingClickDistributionRest =
    (nextClickDistributions[0] * (startMinute / 60)) / nextClickDistributions.length;

  nextClickDistributions[0] = nextClickDistributions[0] * ((60 - startMinute) / 60);

  nextClickDistributions = nextClickDistributions.map((distribution: any, idx: number) => {
    if (idx === 0) return distribution;
    return distribution + startingClickDistributionRest;
  });

  return nextClickDistributions;
};

export const calculateLeftClicks = (gatewaysLength: number, calls: number, start: string) => {
  const startHour = moment(start).hours();
  const startMinute = moment(start).minutes();

  let { previousDistributions, nextClickDistributions } = createClickDistributions(startHour);

  if (startMinute > 0) {
    nextClickDistributions = splitStartingClickDistributionRest(nextClickDistributions, startMinute);
  }

  const clickDistribution = [...previousDistributions, ...nextClickDistributions];

  const arr = Object.values(clickDistribution);
  const max = Math.max(...arr);

  const HIGHEST_HOUR_DISTRIBUTION = 1 / max;
  const IP_CHANGES_PER_HOUR = 12;

  const maxDailyClicks = gatewaysLength * HIGHEST_HOUR_DISTRIBUTION * IP_CHANGES_PER_HOUR;
  const leftClicks = Math.ceil(maxDailyClicks - calls);

  return leftClicks;
};
