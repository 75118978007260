import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Text,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import styled from 'styled-components';

const StyledRouterLink = styled(RouterLink)`
  width: 100%;
  margin-bottom: 8px;
`;

const MailingAccordionItem: React.FC = () => {
  return (
    <AccordionItem border='none' w='100%'>
      <AccordionButton>
        <EmailIcon fontSize='md' />
        <Box flex='1' textAlign='left' pl='3'>
          <Text>Mailing</Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel p={1} outline='none'>
        <StyledRouterLink to='/'>
          <Button variant='ghost' w='100%' p={0}>
            Lista mailingów
          </Button>
        </StyledRouterLink>
        <StyledRouterLink to='/echos'>
          <Button variant='ghost' w='100%'>
            Lista echa mailingów
          </Button>
        </StyledRouterLink>
        <StyledRouterLink to='/mailing/create'>
          <Button variant='ghost' w='100%'>
            Dodaj mailing
          </Button>
        </StyledRouterLink>
        <StyledRouterLink to='/resources'>
          <Button variant='ghost' w='100%'>
            Dostępne zasoby
          </Button>
        </StyledRouterLink>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default MailingAccordionItem;
