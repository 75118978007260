import { FormControl, FormLabel, Input, Stack, Link, Button, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, resetPasswordLink, selectAuth } from './authSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';

const ResetPasswordLink = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);

  const [email, setEmail] = useState('');
  const [resetPasswordLinkError, setResetPasswordLinkError] = useState<boolean>();

  const handleResetPasswordLink = () => {
    dispatch(resetPasswordLink(email));
  };

  useEffect(() => {
    if (status === fetchStatuses.failed) setResetPasswordLinkError(true);
    if (status === fetchStatuses.succeeded) {
      dispatch(clearState());
      navigate('/login');
    }
  }, [status, navigate, dispatch]);

  useEffect(() => {
    setResetPasswordLinkError(false);
  }, [email]);

  return (
    <>
      <Heading fontSize={'4xl'} textAlign={'center'} minW={'80'}>
        Zapomniałem hasła
      </Heading>
      <Stack spacing={4}>
        <FormControl id='email' isInvalid={resetPasswordLinkError} pb={6}>
          <FormLabel>E-mail</FormLabel>
          <Input
            type='email'
            required
            onChange={(e) => {
              setEmail(e.target.value);
              setResetPasswordLinkError(false);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleResetPasswordLink();
              }
            }}
          />
        </FormControl>
        <Button
          onClick={handleResetPasswordLink}
          bg={'teal.300'}
          color={'white'}
          _hover={{
            bg: 'teal.500',
          }}
        >
          Wyślij
        </Button>
        <Link as={RouterLink} to='/login' color={'teal.500'}>
          Powrót do logowania
        </Link>
      </Stack>
    </>
  );
};

export default ResetPasswordLink;
