import {
  Flex,
  Input,
  FormLabel,
  FormControl,
  Button,
  ButtonGroup,
  Switch,
  Heading,
  HStack,
  Divider,
  Spinner,
  Code,
  ListItem,
  UnorderedList,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { useNavigate, useParams } from 'react-router-dom';
import ValidateScript from '../../utils/validateScript';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings, selectSettings } from '../settings/settingsSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { clearState, createScript, selectScript } from './scriptSlice';
import { clearState as clearStateSettings } from '../settings/settingsSlice';
import moment from 'moment';
import { setDefaultLocale } from 'react-datepicker';
import { EditableCodeEditor } from '../../components/codeEditor/EditableCardEditor';
import { getTommorowDate } from '../../utils/getTommorowDate';
import { getIn5MinDate } from '../../utils/getIn5MinDate';
setDefaultLocale('Europe/Warsaw');

const AddScript: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { entities: settings, status: settingsStatus } = useSelector(selectSettings);
  const { status: scriptStatus } = useSelector(selectScript);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
    if (params.mailingId) return;

    navigate('/');
    toast.error('Kampania nie istnieje');
  }, [navigate, params, dispatch]);

  const initialValues = {
    isActive: false,
    isMobile: false,
    isDesktop: false,
    calls: 0,
    start: new Date(),
    bounceRate: 0,
    clickToOpenRate: 0,
    conversionRate: 0,
    startGateway: 0,
    endGateway: 0,
    isMobileScript: false,
    createEcho: true,
    fullReferers: false,
    script: '',
    scriptMobile: '',
    campaign: params.mailingId!,
  };

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (settingsStatus.fetchSettings === fetchStatuses.succeeded) {
      setValues({ ...values, ...settings });
      dispatch(clearStateSettings());
    }
  }, [settingsStatus, settings, setValues, values, dispatch]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeCode = (newScript: string) => {
    setValues({
      ...values,
      script: newScript,
    });
  };

  const handleChangeCodeMobile = (newScript: string) => {
    setValues({
      ...values,
      scriptMobile: newScript,
    });
  };

  const toggleActive = () => {
    setValues({
      ...values,
      isActive: !values.isActive,
    });
  };

  const toggleMobile = () => {
    setValues({
      ...values,
      isMobile: !values.isMobile,
      isDesktop: !values.isMobile ? false : values.isDesktop,
    });
  };

  const toggleDesktop = () => {
    setValues({
      ...values,
      isDesktop: !values.isDesktop,
      isMobile: !values.isDesktop ? false : values.isMobile,
    });
  };

  const toggleIsMobileScript = () => {
    setValues({
      ...values,
      isMobileScript: !values.isMobileScript,
    });
  };

  const toggleCreateEcho = () => {
    setValues({
      ...values,
      createEcho: !values.createEcho,
    });
  };

  const toggleFullReferers = () => {
    setValues({
      ...values,
      fullReferers: !values.fullReferers,
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const scriptData = { ...values, start: moment(values.start).format() };

    const errors = ValidateScript(scriptData);
    if (errors.length > 0) {
      toast.error(errors.join('\n'));
      return;
    }

    dispatch(createScript(scriptData));
  };

  useEffect(() => {
    if (scriptStatus.createScript === fetchStatuses.succeeded) {
      dispatch(clearState());
      navigate('/');
    }
  }, [scriptStatus, navigate, dispatch]);

  const currentDate = moment().format();

  return (
    <Flex
      bg='#ffffff'
      direction={'column'}
      alignItems={'center'}
      rounded='lg'
      boxShadow='xl'
      overflow={'auto'}
      p={6}
      pb={10}
      w='80%'
      h='100%'
    >
      {settingsStatus.fetchSettings === fetchStatuses.loading ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <Flex direction={'column'} alignItems={'center'}>
          <Heading size={'md'} textTransform='uppercase'>
            dodawanie skryptu
          </Heading>
          <S.Form onSubmit={(e) => onSubmit(e)}>
            <Flex direction='column' flex={1} overflow='auto' gap='20px' maxW='900px' w='100%' p={6} pr={10}>
              <HStack alignItems={'flex-start'} gap={4}>
                <FormControl>
                  <FormLabel>Początek działania</FormLabel>
                  {values.start && (
                    <>
                      <ButtonGroup position={'absolute'} top={'0px'} right={'0px'}>
                        <Button
                          size={'xs'}
                          colorScheme={'yellow'}
                          onClick={() => {
                            setValues((prevState) => ({ ...prevState, start: getIn5MinDate() }));
                          }}
                        >
                          ustaw na teraz
                        </Button>
                        <Button
                          size={'xs'}
                          colorScheme={'blue'}
                          onClick={() => {
                            setValues((prevState) => ({ ...prevState, start: getTommorowDate() }));
                          }}
                        >
                          ustaw na jutro
                        </Button>
                      </ButtonGroup>
                      <S.StyledDatePicker
                        name='start'
                        selected={new Date(values.start)}
                        showTimeSelect
                        onChange={(date: Date) => {
                          if (moment(date).day() > moment().day()) {
                            const futureDate = new Date(
                              moment(date).set('hour', 1).set('minutes', 0).format()
                            );
                            setValues((prevState) => ({
                              ...prevState,
                              start: futureDate,
                            }));
                          } else setValues((prevState) => ({ ...prevState, start: date }));
                        }}
                        dateFormat='Pp'
                        timeIntervals={5}
                        minDate={new Date(currentDate)}
                        minTime={
                          moment(new Date(values.start)).isSame(new Date(), 'day')
                            ? new Date(currentDate)
                            : moment().startOf('day').toDate()
                        }
                        maxTime={moment().endOf('day').toDate()}
                      />
                    </>
                  )}
                </FormControl>
                <FormControl display={'flex'}>
                  <FormControl>
                    <FormLabel>Aktywny</FormLabel>
                    <Switch
                      colorScheme='teal'
                      size='md'
                      isChecked={values.isActive}
                      onChange={toggleActive}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Tylko mobilne urządzenia</FormLabel>
                    <Switch
                      colorScheme='teal'
                      size='md'
                      isChecked={values.isMobile}
                      onChange={toggleMobile}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Tylko desktopowe urządzenia</FormLabel>
                    <Switch
                      colorScheme='teal'
                      size='md'
                      isChecked={values.isDesktop}
                      onChange={toggleDesktop}
                    />
                  </FormControl>
                </FormControl>
              </HStack>
              <Divider></Divider>
              <HStack alignItems={'flex-start'} gap={4}>
                <FormControl>
                  <FormLabel>Przeciętny Bounce Rate</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    name='bounceRate'
                    type='number'
                    value={values.bounceRate}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Przeciętny Clik To Open Rate</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    name='clickToOpenRate'
                    type='number'
                    value={values.clickToOpenRate}
                    required
                  />
                </FormControl>
              </HStack>
              <HStack>
                <FormControl>
                  <FormLabel>
                    Dopuszczalna % różnica pomiędzy próbami wywołania skryptu a jego sukcesem
                  </FormLabel>
                  <Input
                    onChange={handleInputChange}
                    name='conversionRate'
                    type='number'
                    value={values.conversionRate}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Wywołania</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    name='calls'
                    type='number'
                    value={values.calls}
                    required
                    min={1}
                  />
                </FormControl>
              </HStack>
              <HStack>
                <FormControl>
                  <FormLabel>Początkowy gateway (min: {settings.startGateway})</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    name='startGateway'
                    type='number'
                    value={values.startGateway}
                    required
                    min={settings.startGateway}
                    max={values.endGateway}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Końcowy gateway (max: {settings.endGateway})</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    name='endGateway'
                    type='number'
                    value={values.endGateway}
                    required
                    min={values.startGateway}
                    max={settings.endGateway}
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>Dodatkowy skrypt - wersja mobilna</FormLabel>
                <Switch
                  colorScheme='teal'
                  size='md'
                  isChecked={values.isMobileScript}
                  onChange={toggleIsMobileScript}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Stwórz echo skryptu (wyłączony - display)</FormLabel>
                <Switch
                  colorScheme='teal'
                  size='md'
                  isChecked={values.createEcho}
                  onChange={toggleCreateEcho}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Wysyłaj 100% refererów</FormLabel>
                <Switch
                  colorScheme='teal'
                  size='md'
                  isChecked={values.fullReferers}
                  onChange={toggleFullReferers}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Skrypt</FormLabel>
                <Flex flex={1} minH='200px' w='100%' position='relative'>
                  <EditableCodeEditor
                    value={values.script}
                    language='js'
                    handleChangeCode={handleChangeCode}
                  />
                </Flex>
              </FormControl>
              {values.isMobileScript && (
                <FormControl>
                  <FormLabel>Skrypt mobilny</FormLabel>
                  <Flex flex={1} minH='200px' w='100%' position='relative'>
                    <EditableCodeEditor
                      value={values.scriptMobile}
                      language='js'
                      handleChangeCode={handleChangeCodeMobile}
                    />
                  </Flex>
                </FormControl>
              )}
              <Heading size={'md'}>Pomocnicze funkcje</Heading>
              <UnorderedList>
                <ListItem>
                  <Text>Losowy timeout z przedziału [x, y] w sekundach:</Text>
                  <Code>await crawlerHelper.sleep(x, y, page);</Code>
                </ListItem>
                <ListItem>
                  Kliknięcie w IFrame:
                  <Code>
                    await crawlerHelper.clickIFrame(page, {`<selector iframe'a>`},{' '}
                    {`<selector, w który chcemy kliknąć w iframe>`});
                  </Code>
                </ListItem>
                <ListItem>
                  Otwarcie nowej karty i zwrócenie do niej odnośnika:
                  <Code>const newPage = await crawlerHelper.openNewTabAndReturnNewPage(browser, page);</Code>
                </ListItem>
              </UnorderedList>
              <Heading size={'md'}>
                Przykład użycia kliknięcia w IFrame i otwarcia nowej karty na podstawie kampanii Samsung
              </Heading>
              <UnorderedList>
                <ListItem>
                  Kliknięcie w IFrame:
                  <Code>
                    {`await crawlerHelper.clickIFrame(page, ".mfp-iframe", ".OnlineStores_rowContainer__1rIeS");`}
                  </Code>
                </ListItem>
                <ListItem>
                  Otwarcie nowej karty i zwrócenie do niej odnośnika (w tym przypadku mediaExpertPage):
                  <Code>
                    {`const mediaExpertPage = await crawlerHelper.openNewTabAndReturnNewPage(browser, page);`}
                  </Code>
                </ListItem>
                <ListItem>
                  Kliknięcie w slider w nowej karcie - zamiast page przekazujemy mediaExpertPage:
                  <Code>
                    {`await crawlerHelper.clickWrapper(
                    mediaExpertPage,
                    ".picture > .product-gallery-view > .slider-arrows > .arrow-right > .button-content",
                  );`}
                  </Code>
                </ListItem>
              </UnorderedList>
              <Flex justifyContent='flex-end'>
                <Flex maxW='500px' w='100%' justifyContent={'flex-end'}>
                  <ButtonGroup spacing={6}>
                    <Button colorScheme='red' onClick={() => navigate('/mailing/create')}>
                      Wróć
                    </Button>
                    <Button colorScheme='teal' type='submit'>
                      Dodaj
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Flex>
            </Flex>
          </S.Form>
        </Flex>
      )}
    </Flex>
  );
};

export default AddScript;
