import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spinner,
  Textarea,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { fetchSettings, selectSettings, updateSettings } from './settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { toast } from 'react-toastify';
import { SettingsProps } from '../../interface/settings/Settings';
import ValidateSettings from '../../utils/validateSettings';
import RestartCrawlers from './buttons/RestartCrawlers';
import ClearQueues from './buttons/ClearQueues';
import StopAllScripts from './buttons/StopAllScripts';

const Admin: React.FC = () => {
  const { entities: settings, status } = useSelector(selectSettings);
  const dispatch = useDispatch();

  const [localSettings, setLocalSettings] = useState<SettingsProps>({
    bounceRate: 0,
    clickToOpenRate: 0,
    conversionRate: 0,
    mobileRate: 0,
    powerDistribution: '0.2\n0.1\n0.05',
  });

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (status.fetchSettings === fetchStatuses.succeeded) {
      setLocalSettings({ ...settings, powerDistribution: settings.powerDistribution.join('\n') });
    }
  }, [status, settings]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setLocalSettings((prevState: SettingsProps) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const validateInputs = () => {
    let settingsValid = true;

    const errors = ValidateSettings(localSettings);
    if (errors.length > 0) {
      toast.error(errors.join('\n'));
      settingsValid = false;
    }

    return settingsValid;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (validateInputs()) {
      dispatch(
        updateSettings({
          ...localSettings,
          powerDistribution: localSettings.powerDistribution.split('\n').map((item: string) => +item),
        })
      );
    }
  };

  return (
    <Flex
      bg='#ffffff'
      direction={'column'}
      alignItems={'center'}
      rounded='lg'
      boxShadow='xl'
      overflow={'auto'}
      p={6}
      pb={10}
      w='80%'
      h='100%'
    >
      {status.fetchSettings !== fetchStatuses.succeeded || Object.keys(localSettings).length === 0 ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <Flex direction={'column'} alignItems={'center'}>
          <Heading size={'md'} textTransform='uppercase'>
            Administracja
          </Heading>
          <Wrap mt='1rem' spacing='1rem' justify='center'>
            <WrapItem>
              <RestartCrawlers />
              <ClearQueues />
              <StopAllScripts />
            </WrapItem>
          </Wrap>
          <Heading mt='1rem' size={'md'} textTransform='uppercase'>
            Zmienne globalne
          </Heading>
          <S.Form onSubmit={(e) => onSubmit(e)}>
            <Flex direction='column' flex={1} overflow='auto' gap='20px' maxW='900px' w='100%' p={6} pr={10}>
              <HStack alignItems={'flex-end'} gap={4}>
                <FormControl>
                  <FormLabel htmlFor='bounceRate'>Przeciętny Bounce Rate</FormLabel>
                  <Input
                    value={localSettings?.bounceRate}
                    name='bounceRate'
                    id='bounceRate'
                    onChange={handleInputChange}
                    type='number'
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='clickToOpenRate'>Przeciętny Clik To Open Rate</FormLabel>
                  <Input
                    value={localSettings?.clickToOpenRate}
                    name='clickToOpenRate'
                    id='clickToOpenRate'
                    onChange={handleInputChange}
                    type='number'
                    required
                  />
                </FormControl>
              </HStack>
              <HStack alignItems={'flex-end'} gap={4}>
                <FormControl>
                  <FormLabel htmlFor='conversionRate'>
                    Dopuszczalna % różnica pomiędzy próbami wywołania skryptu a jego sukcesem
                  </FormLabel>
                  <Input
                    value={localSettings?.conversionRate}
                    name='conversionRate'
                    id='conversionRate'
                    onChange={handleInputChange}
                    type='number'
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='mobileRate'>Stosunek mobilnych kampanii do stacjonarnych</FormLabel>
                  <Input
                    value={localSettings?.mobileRate}
                    name='mobileRate'
                    id='mobileRate'
                    onChange={handleInputChange}
                    type='number'
                    required
                  />
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel htmlFor='conversionRate'>Rozkład mocy echa kampanii</FormLabel>
                <Textarea
                  value={localSettings?.powerDistribution}
                  name='powerDistribution'
                  id='powerDistribution'
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
              <Flex justifyContent='flex-end'>
                <Button colorScheme='teal' type='submit'>
                  Zatwierdź
                </Button>
              </Flex>
            </Flex>
          </S.Form>
        </Flex>
      )}
    </Flex>
  );
};

export default Admin;
