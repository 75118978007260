import React from 'react';
import { Button, Flex, FormControl, Select, FormLabel, Box } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

interface PaginationProps {
  updatePaginationValues: (paginationValues: { limit: number; page: number }) => void;
  propsPaginationValues: { limit: number; page: number };
  quantity: number;
}

const TablePagination: React.FC<PaginationProps> = ({
  updatePaginationValues,
  propsPaginationValues,
  quantity,
}: PaginationProps) => {
  const pagesVisible = 4;

  const handlePaginationValuesChange = (e: any) => {
    const { name, value } = e.target;

    changePage(1);

    propsPaginationValues = {
      ...propsPaginationValues,
      [name]: value,
    };

    updatePaginationValues(propsPaginationValues);
  };

  const calculateLastPage = () => {
    if (quantity === 0) {
      return 1;
    }
    return quantity % propsPaginationValues.limit === 0
      ? Math.floor(quantity / propsPaginationValues.limit)
      : Math.floor(quantity / propsPaginationValues.limit) + 1;
  };

  const changePage = (newPage: number) => {
    propsPaginationValues = {
      ...propsPaginationValues,
      page: newPage,
    };

    updatePaginationValues(propsPaginationValues);
  };

  const calculatePagesVisible = (): number => {
    if (calculateLastPage() < pagesVisible) {
      return calculateLastPage();
    }
    return pagesVisible;
  };

  const generatePages = (currentPage: number): number[] => {
    const pages = Array.from({ length: calculatePagesVisible() }, (e, index) => {
      if (currentPage > calculateLastPage() - calculatePagesVisible()) {
        return calculateLastPage() - calculatePagesVisible() + 1 + index;
      }
      return currentPage + index;
    });
    return pages;
  };

  return (
    <Flex mt='1rem' direction='row' alignItems='flex-end'>
      <FormControl width='auto'>
        <FormLabel>Ilość elementów na stronie</FormLabel>
        <Select
          name='limit'
          id='limit'
          value={propsPaginationValues.limit}
          onChange={handlePaginationValuesChange}
          borderColor='gray'
        >
          <option value='25'>25</option>
          <option value='50'>50</option>
          <option value='100'>100</option>
        </Select>
      </FormControl>
      <Flex direction='row' align='center' margin='0 auto'>
        <ArrowLeftIcon
          boxSize={4}
          color={propsPaginationValues.page > 1 ? 'teal' : 'gray'}
          mr='1rem'
          onClick={propsPaginationValues.page > 1 ? () => changePage(1) : undefined}
          cursor={propsPaginationValues.page > 1 ? 'pointer' : 'default'}
        />
        <ChevronLeftIcon
          boxSize={8}
          color={propsPaginationValues.page > 1 ? 'teal' : 'gray'}
          mr='1rem'
          onClick={
            propsPaginationValues.page > 1 ? () => changePage(propsPaginationValues.page - 1) : undefined
          }
          cursor={propsPaginationValues.page > 1 ? 'pointer' : 'default'}
        />
        {generatePages(propsPaginationValues.page).map((page, i) => {
          return (
            <Button
              colorScheme='teal'
              variant={propsPaginationValues.page === page ? 'outline' : 'link'}
              mr='1rem'
              onClick={() => changePage(page)}
              key={i}
            >
              {page}
            </Button>
          );
        })}
        <ChevronRightIcon
          boxSize={8}
          color={propsPaginationValues.page !== calculateLastPage() ? 'teal' : 'gray'}
          onClick={
            propsPaginationValues.page !== calculateLastPage()
              ? () => changePage(propsPaginationValues.page + 1)
              : undefined
          }
          cursor={propsPaginationValues.page !== calculateLastPage() ? ' pointer' : 'default'}
        />
        <ArrowRightIcon
          boxSize={4}
          color={propsPaginationValues.page !== calculateLastPage() ? 'teal' : 'gray'}
          mr='1rem'
          onClick={
            propsPaginationValues.page !== calculateLastPage()
              ? () => changePage(calculateLastPage())
              : undefined
          }
          cursor={propsPaginationValues.page !== calculateLastPage() ? ' pointer' : 'default'}
        />
      </Flex>
      <Box marginLeft='auto'></Box>
    </Flex>
  );
};

export default TablePagination;
