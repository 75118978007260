import { CampaignProps } from '../../interface/campaign/Campaign';
import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CampaignService from '../../services/campaignService';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { CampaignQueryParams } from '../../interface/campaign/CamapignQueryParams';
import { toast } from 'react-toastify';

const initialState: GlobalState = {
  entities: {},
  status: fetchStatuses.idle,
  error: false,
};

export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  async (campaignQueryParams?: CampaignQueryParams): Promise<CampaignProps[]> => {
    const campaigns = await CampaignService.getAllCampaigns(campaignQueryParams);
    return campaigns;
  }
);

export const deleteCampaign = createAsyncThunk(
  'campaigns/deleteCampaign',
  async (id: string): Promise<void> => {
    await CampaignService.deleteCampaign(id);
  }
);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCampaigns.pending, (state, action) => {
        state.status = {
          ...state.status,
          fetchCampaigns: fetchStatuses.loading,
          deleteCampaign: fetchStatuses.idle,
        };
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = {
          ...state.status,
          fetchCampaigns: fetchStatuses.idle,
          deleteCampaign: fetchStatuses.idle,
        };
        state.entities = action.payload;
      })
      .addCase(deleteCampaign.pending, (state, action) => {
        state.status = { ...state.status, deleteCampaign: fetchStatuses.loading };
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        toast.success('Usunięto kampanie');
        state.status = { ...state.status, deleteCampaign: fetchStatuses.succeeded };
      });
  },
});

export const selectCampaigns = (state: any) => state.campaigns;

export default campaignsSlice.reducer;
