import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuth } from '../features/auth/authSlice';

export const PrivateRoute: React.FC = () => {
  const { entities: auth } = useSelector(selectAuth);

  if (auth && auth.email) {
    return <Outlet />;
  } else {
    return <Navigate to='/login' />;
  }
};
