import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Heading,
  HStack,
  Button,
  Flex,
  Spinner,
  FormControl,
  FormLabel,
  Select,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EchoProps } from '../../interface/echo/Echo';
import { fetchEchos, selectEchos } from './echosSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import CalculatePercent from '../../utils/calculatePercent';
import FormatDate from '../../utils/formatDate';
import { returnIcon } from '../../utils/returnAvailabilityIcon';
import TablePagination from '../../components/pagination/TablePagination';
import { EchoQueryFilters, EchoQueryParams } from '../../interface/echo/EchoQueryParams';
import { toast } from 'react-toastify';
import { sendTestScript } from '../script/scriptSlice';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { calculateLeftClicks } from '../../utils/calculateLeftClicks';
import { calculateActionCompletion } from '../../utils/calculateActionCompletion';

const Echos: React.FC = () => {
  let navigate = useNavigate();
  const { entities: echos, status } = useSelector(selectEchos);
  const dispatch = useDispatch();

  const [echoQueryParams, setEchoQueryParams] = useState<EchoQueryParams>({
    limit: 50,
    page: 1,
    sort: '-script.start',
  });

  const [filtersVisible, setFiltersVisible] = useState(true);

  const [echoQueryFilters, setEchoQueryFilters] = useState<EchoQueryFilters>({
    'campaign.calendarName': '',
    'campaign.anonymousUrl': '',
  });

  const toggleFiltersVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onFiltersSubmit = (e: any) => {
    e.preventDefault();

    setEchoQueryParams({
      ...echoQueryParams,
      filters: {
        ...(echoQueryFilters['campaign.calendarName'] && {
          'campaign.calendarName': echoQueryFilters['campaign.calendarName'],
        }),
        ...(echoQueryFilters['campaign.anonymousUrl'] && {
          'campaign.anonymousUrl': echoQueryFilters['campaign.anonymousUrl'],
        }),
      },
    });
  };

  const clearEchoQueryFilters = () => {
    setEchoQueryFilters({
      'campaign.calendarName': '',
      'campaign.anonymousUrl': '',
    });
    setEchoQueryParams({
      ...echoQueryParams,
      filters: {},
    });
  };

  const updatePaginationValues = (paginationValues: { limit: number; page: number }) => {
    setEchoQueryParams({
      ...echoQueryParams,
      ...paginationValues,
    });
  };

  const handleFilterParamsChange = (e: any) => {
    const { value, name } = e.target;

    setEchoQueryFilters({
      ...echoQueryFilters,
      [name]: value,
    });
  };

  const handleQueryParamsChange = (e: any) => {
    const { value, name } = e.target;

    setEchoQueryParams({
      ...echoQueryParams,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(fetchEchos(echoQueryParams));
  }, [dispatch, echoQueryParams]);

  return (
    <Box
      w='100%'
      h='100%'
      bg={'whiteAlpha.900'}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      rounded='md'
      boxShadow='xl'
      p={3}
      overflow='auto'
    >
      {status === fetchStatuses.loading ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <Flex direction={'column'}>
          <Heading size={'md'} textTransform='uppercase' textAlign='center' my={3}>
            Lista echa mailingów
          </Heading>
          <Flex my='1rem' direction='row' justifyContent='space-between' align='end'>
            <FormControl width='auto'>
              <FormLabel>Sortowanie:</FormLabel>
              <Select
                name='sort'
                id='sort'
                value={echoQueryParams.sort}
                onChange={handleQueryParamsChange}
                borderColor='gray'
              >
                <option value='-script.start'>Najnowsze</option>
                <option value='script.start'>Najstarsze</option>
                <option value='calendarName'>Nazwa - A do Z</option>
                <option value='-calendarName'>Nazwa - Z do A</option>
              </Select>
            </FormControl>
            {filtersVisible && (
              <form onSubmit={(e) => onFiltersSubmit(e)}>
                <Flex alignItems={'flex-end'} gap={2}>
                  <FormControl>
                    <FormLabel>Nazwa kampanii: </FormLabel>
                    <Input
                      type='text'
                      name='campaign.calendarName'
                      id='calendarName'
                      value={echoQueryFilters?.['campaign.calendarName']}
                      onChange={handleFilterParamsChange}
                      borderColor='gray'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Zanonimizowany URL: </FormLabel>
                    <Input
                      name='campaign.anonymousUrl'
                      id='anonymousUrl'
                      value={echoQueryFilters?.['campaign.anonymousUrl']}
                      onChange={handleFilterParamsChange}
                      borderColor='gray'
                    />
                  </FormControl>
                  <Button colorScheme='green' type='submit'>
                    Filtruj
                  </Button>
                  {(echoQueryParams.filters?.['campaign.anonymousUrl'] ||
                    echoQueryParams.filters?.['campaign.calendarName']) && (
                    <Button type='button' colorScheme='red' onClick={clearEchoQueryFilters} w='180px'>
                      Usuń filtry
                    </Button>
                  )}
                </Flex>
              </form>
            )}
            <Button colorScheme='teal' onClick={toggleFiltersVisibility}>
              {!filtersVisible ? 'Pokaż' : 'Schowaj'} filtry
            </Button>
          </Flex>
          <Table variant='striped' colorScheme='blackAlpha' size='sm'>
            <Thead>
              <Tr>
                <Th w='30%'>Nazwa</Th>
                <Th w='15%'>Data startu</Th>
                <Th w='5%'>skrypt aktywny</Th>
                <Th w='10%'>Realizacja akcji</Th>
                <Th w='10%'>Realizacja otwarć i akcji</Th>
                <Th w='10%'>Kliknięcia w kolejce</Th>
                <Th w='10%'>Kliknięcia możliwe do dodania</Th>
                <Th w='10%'>Operacje</Th>
              </Tr>
            </Thead>
            <Tbody>
              {echos.items &&
                echos.items.length > 0 &&
                echos.items.map((item: EchoProps) => {
                  const gatewaysLength = item.script?.endGateway! + 1 - item.script?.startGateway!;
                  return (
                    <Tr key={item._id}>
                      <Td>
                        <Text fontSize='lg'>{`Echo ${item.power} - ${item.campaign!.calendarName}`}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.script ? FormatDate(item.script?.start, ' ') : ''}</Text>
                      </Td>
                      <Td textAlign={'center'}>{returnIcon(item.script?.isActive ?? false)}</Td>
                      <Td>
                        <Text fontSize='lg'>{item.script && calculateActionCompletion(item.script)}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>
                          {item.script && item.script.callsDone! >= 0 && item.script?.allActions! >= 0
                            ? `${CalculatePercent(item.script?.callsDone!, item.script?.allActions!)}% - ${
                                item.script?.callsDone
                              } z ${item.script?.allActions}`
                            : ''}
                        </Text>
                      </Td>

                      <Td>
                        <Text fontSize='lg'>{item.script ? item.script.queuedClicks! : ''}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>
                          {item.script && item.script?.allActions!
                            ? calculateLeftClicks(gatewaysLength, item.script?.allActions!, item.script.start)
                            : ''}
                        </Text>
                      </Td>
                      <Td>
                        <HStack minW='250px' flexWrap={'wrap'} gap='3px'>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'red'}
                            onClick={() => navigate(`/echos/${item._id}`)}
                          >
                            Pokaż
                          </S.DashboardButton>
                          {item.campaign && (
                            <S.DashboardButton
                              size={'xs'}
                              colorScheme={'yellow'}
                              onClick={() => navigate(`/mailing/${item.campaign?._id}/edit`)}
                            >
                              Mailing
                            </S.DashboardButton>
                          )}
                          {item.script && (
                            <>
                              <S.DashboardButton
                                size={'xs'}
                                colorScheme={'teal'}
                                onClick={() => {
                                  navigate(`/script/${item.script?._id!}/edit`);
                                }}
                              >
                                Skrypt
                              </S.DashboardButton>
                              <S.DashboardButton
                                size={'xs'}
                                colorScheme={'red'}
                                onClick={() => {
                                  if (
                                    item.script &&
                                    Object.keys(item.script).length > 0 &&
                                    item.script.callsDone! > 0
                                  ) {
                                    navigate(`/script/${item.script!._id!}/logs`);
                                  } else {
                                    toast.error('Echo nie posiada skryptu - nie możesz zobaczyć logów');
                                  }
                                }}
                              >
                                Logi
                              </S.DashboardButton>
                              <S.DashboardButton
                                size={'xs'}
                                colorScheme={'yellow'}
                                onClick={() => {
                                  if (item.script && Object.keys(item.script).length > 0)
                                    dispatch(sendTestScript(item.script!._id!));
                                }}
                              >
                                Test
                              </S.DashboardButton>
                            </>
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Flex>
      )}
      {echos.items && (
        <TablePagination
          propsPaginationValues={{
            limit: echoQueryParams.limit,
            page: echoQueryParams.page,
          }}
          quantity={echos.count}
          updatePaginationValues={updatePaginationValues}
        ></TablePagination>
      )}
    </Box>
  );
};

export default Echos;
