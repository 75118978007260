import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SingleCampaignMailingCard from '../../components/SingleCampaign/SingleCampaignMailingCard';
import SingleCampaignScriptCard from '../../components/SingleCampaign/SingleCampaignScriptCard';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { clearState, fetchSingleEcho, selectEcho } from './echoSlice';

const SingleEcho: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { entities: echo, status } = useSelector(selectEcho);

  useEffect(() => {
    if (!params.id) return;
    dispatch(fetchSingleEcho(params.id!));
  }, [params, dispatch]);

  useEffect(() => {
    if (status.fetchSingleEcho === fetchStatuses.succeeded) {
      dispatch(clearState());
    }
  }, [status, dispatch]);

  if (!echo || !echo.campaign || status === fetchStatuses.loading) {
    return (
      <Flex minH='100vh' w='100%' align='center' justify='center'>
        <Spinner size='xl' />
      </Flex>
    );
  }

  return (
    <Flex w={'100%'} h={'100%'} pr={8} justifyContent={'space-between'} gap={8}>
      <SingleCampaignMailingCard
        mailing={{
          calendarName: `Echo ${echo.power} - ${echo.campaign.calendarName}`,
          anonymousUrl: echo.campaign.anonymousUrl,
          id: echo.campaign._id!,
          scriptId: echo.script && echo.script.callsDone > 0 ? echo.script._id! : '',
        }}
        creations={{
          originalHtml: echo.campaign.mailing,
          anonymousHtml: echo.campaign.anonymousHtml || '',
          resultHtml: echo.campaign.resultHtml || '',
          script: echo.script && echo.script.script ? echo.script.script : '',
        }}
      />
      {echo.script && (
        <SingleCampaignScriptCard
          scriptData={{
            startDate: echo.script.start,
            bounceRate: echo.script.bounceRate,
            clickToOpenRate: echo.script.clickToOpenRate,
            conversionRate: echo.script.conversionRate,
            activeScript: echo.script.isActive,
            mobileScript: echo.script.isMobile,
            desktopScript: echo.script.isDesktop,
            allActions: echo.script.allActions || 0,
            openActions: echo.script.openActions || 0,
            fullActions: echo.script.fullActions || 0,
            bounceActions: echo.script.bounceActions || 0,
            callsDone: echo.script.callsDone || 0,
            callsOpen: echo.script.callsOpen || 0,
            callsFull: echo.script.callsFull || 0,
            callsBounced: echo.script.callsBounced || 0,
            callsFailed: echo.script.callsFailed || 0,
            id: echo.script._id!,
          }}
        />
      )}
    </Flex>
  );
};

export default SingleEcho;
