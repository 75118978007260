import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import AddMailing from '../features/campaign/AddMailing';
import AddScript from '../features/script/AddScript';
import Admin from '../features/settings/Admin';
import Gateways from '../features/gateways/Gateways';
import EditMailing from '../features/campaign/EditMailing';
import EditScript from '../features/script/EditScript';
import Login from '../features/auth/Login';
import MailingCampaignList from '../features/campaigns/MailingCampaignList';
import ResetPassword from '../features/auth/ResetPassword';
import ResetPasswordLink from '../features/auth/ResetPasswordLink';
import SingleCampaign from '../features/campaign/SingleCampaign';
import Resources from '../features/resources/Resources';
import Echos from '../features/echos/Echos';
import AddEcho from '../features/echo/AddEcho';
import SingleEcho from '../features/echo/SingleEcho';
import CloneMailing from '../features/campaign/CloneMailing';
import CloneScript from '../features/script/CloneScript';
import ScriptLogs from '../features/script/ScriptLogs';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={<PublicRoute />}>
        <Route path='/login' element={<Login />} />
      </Route>
      <Route path='/reset-password/:id' element={<PublicRoute />}>
        <Route path='/reset-password/:id' element={<ResetPassword />} />
      </Route>
      <Route path='/reset-password-link' element={<PublicRoute />}>
        <Route path='/reset-password-link' element={<ResetPasswordLink />} />
      </Route>
      <Route path='*' element={<PublicRoute />}>
        <Route path='*' element={<Login />} />
      </Route>
      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<MailingCampaignList />} />
      </Route>
      <Route path='/mailing/create' element={<PrivateRoute />}>
        <Route path='/mailing/create' element={<AddMailing />} />
      </Route>
      <Route path='/mailing/:id/edit' element={<PrivateRoute />}>
        <Route path='/mailing/:id/edit' element={<EditMailing />} />
      </Route>
      <Route path='/mailing/:id/clone' element={<PrivateRoute />}>
        <Route path='/mailing/:id/clone' element={<CloneMailing />} />
      </Route>
      <Route path='/script/create/:mailingId' element={<PrivateRoute />}>
        <Route path='/script/create/:mailingId' element={<AddScript />} />
      </Route>
      <Route path='/script/:id/edit' element={<PrivateRoute />}>
        <Route path='/script/:id/edit' element={<EditScript />} />
      </Route>
      <Route path='/script/:id/clone/:mailingId' element={<PrivateRoute />}>
        <Route path='/script/:id/clone/:mailingId' element={<CloneScript />} />
      </Route>
      <Route path='/script/:id/logs' element={<PrivateRoute />}>
        <Route path='/script/:id/logs' element={<ScriptLogs />} />
      </Route>
      <Route path='/campaigns/:id' element={<PrivateRoute />}>
        <Route path='/campaigns/:id' element={<SingleCampaign />} />
      </Route>
      <Route path='/resources' element={<PrivateRoute />}>
        <Route path='/resources' element={<Resources />} />
      </Route>
      <Route path='/echos' element={<PrivateRoute />}>
        <Route path='/echos' element={<Echos />} />
      </Route>
      <Route path='/echos/create/:mailingId' element={<PrivateRoute />}>
        <Route path='/echos/create/:mailingId' element={<AddEcho />} />
      </Route>
      <Route path='/echos/:id' element={<PrivateRoute />}>
        <Route path='/echos/:id' element={<SingleEcho />} />
      </Route>
      <Route path='/admin' element={<PrivateRoute />}>
        <Route path='/admin' element={<Admin />} />
      </Route>
      <Route path='/gateways' element={<PrivateRoute />}>
        <Route path='/gateways' element={<Gateways />} />
      </Route>
      <Route path='*' element={<PrivateRoute />}>
        <Route path='*' element={<MailingCampaignList />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
