import CodeEditor from '@uiw/react-textarea-code-editor';

interface CardCodeEditorProps {
  value: string;
  language: string;
}

export const CardCodeEditor = ({ value, language }: CardCodeEditorProps) => {
  return (
    <div data-color-mode='dark'>
      <CodeEditor
        value={value}
        language={language}
        padding={15}
        readOnly={true}
        style={{
          maxHeight: '450px',
          overflow: 'auto',
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          fontSize: 12,
          borderRadius: '0.375rem',
          maxWidth: '50vw',
        }}
      />
    </div>
  );
};
