import React, { useEffect, useState } from 'react';
import { Button, Flex, FormControl, Heading, Spinner, Textarea } from '@chakra-ui/react';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { selectGateways, fetchGateways, updateGateways } from './gatewaysSlice';

const Gateways: React.FC = () => {
  const { entities, status } = useSelector(selectGateways);
  const dispatch = useDispatch();

  const [localGateways, setLocalGateways] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchGateways());
  }, [dispatch]);

  useEffect(() => {
    if (status.fetchGateways === fetchStatuses.succeeded) {
      setLocalGateways(entities.gateways);
    }
  }, [status, entities]);

  const handleInputChange = (e: any) => {
    setLocalGateways(e.target.value.split('\n'));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    dispatch(updateGateways(localGateways));
  };

  return (
    <Flex
      bg='#ffffff'
      direction={'column'}
      alignItems={'center'}
      rounded='lg'
      boxShadow='xl'
      overflow={'auto'}
      p={6}
      pb={10}
      w='80%'
      h='100%'
    >
      {status.fetchGateways !== fetchStatuses.succeeded ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <Flex direction={'column'} alignItems={'center'} w='100%' h='100%'>
          <Heading size={'md'} textTransform='uppercase'>
            Gatewaye
          </Heading>
          <S.Form onSubmit={(e) => onSubmit(e)}>
            <Flex direction='column' flex={1} overflow='auto' gap='20px' w='100%' p={6} pr={10}>
              <FormControl minH={'90%'}>
                <Textarea
                  value={localGateways ? localGateways.join('\n') : []}
                  name='localGateways'
                  id='localGateways'
                  onChange={handleInputChange}
                  required
                  h={'100%'}
                />
              </FormControl>
              <Flex justifyContent='flex-end'>
                <Button colorScheme='teal' type='submit'>
                  Zatwierdź
                </Button>
              </Flex>
            </Flex>
          </S.Form>
        </Flex>
      )}
    </Flex>
  );
};

export default Gateways;
