import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { EchoProps } from '../../interface/echo/Echo';
import EchoService from '../../services/echoService';
import { EchoQueryParams } from '../../interface/echo/EchoQueryParams';

const initialState: GlobalState = {
  entities: {},
  status: fetchStatuses.idle,
  error: false,
};

export const fetchEchos = createAsyncThunk(
  'echos/fetchEchos',
  async (echoQueryParams?: EchoQueryParams): Promise<EchoProps[]> => {
    const echos = await EchoService.getAllEchos(echoQueryParams);
    return echos;
  }
);

const echosSlice = createSlice({
  name: 'echos',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchEchos.pending, (state, action) => {
        state.status = fetchStatuses.loading;
      })
      .addCase(fetchEchos.fulfilled, (state, action) => {
        state.status = fetchStatuses.idle;
        state.entities = action.payload;
      });
  },
});

export const selectEchos = (state: any) => state.echos;

export default echosSlice.reducer;
