import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, resetPassword, selectAuth } from './authSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';

const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);

  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState<boolean>();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

  const handleResetPassword = async () => {
    dispatch(
      resetPassword({
        id: params.id!,
        passwords: {
          newPassword,
          confirmationPassword,
        },
      })
    );
  };

  useEffect(() => {
    if (status === fetchStatuses.failed) setResetPasswordError(true);
    if (status === fetchStatuses.succeeded) {
      dispatch(clearState());
      navigate('/login');
    }
  }, [status, navigate, dispatch]);

  useEffect(() => {
    setResetPasswordError(false);
  }, [newPassword, confirmationPassword]);

  return (
    <>
      <Heading fontSize={'4xl'} textAlign={'center'} minW={'80'}>
        Reset hasła
      </Heading>
      <Stack spacing={4}>
        <FormControl id='password' isInvalid={resetPasswordError} pb={6}>
          <FormLabel>Nowe hasło</FormLabel>
          <InputGroup>
            <Input
              type={showNewPassword ? 'text' : 'password'}
              required
              onChange={(e) => {
                setNewPassword(e.target.value);
                setResetPasswordError(false);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleResetPassword();
                }
              }}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={() => setShowNewPassword(!showNewPassword)}>
                {showNewPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id='confirmPassword' isInvalid={resetPasswordError} pb={6}>
          <FormLabel>Potwierdź hasło</FormLabel>
          <InputGroup>
            <Input
              type={showConfirmationPassword ? 'text' : 'password'}
              required
              onChange={(e) => {
                setConfirmationPassword(e.target.value);
                setResetPasswordError(false);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleResetPassword();
                }
              }}
            />
            <InputRightElement width='4.5rem'>
              <Button
                h='1.75rem'
                size='sm'
                onClick={() => setShowConfirmationPassword(!showConfirmationPassword)}
              >
                {showNewPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button
          onClick={handleResetPassword}
          bg={'teal.300'}
          color={'white'}
          _hover={{
            bg: 'teal.500',
          }}
        >
          Wyślij
        </Button>
        <Link as={RouterLink} to='/login' color={'teal.500'}>
          Powrót do logowania
        </Link>
      </Stack>
    </>
  );
};

export default ResetPassword;
