import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Button,
  ButtonGroup,
  Heading,
  FormLabel,
  FormControl,
  Input,
  HStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { toast } from 'react-toastify';
import kBtoBytes from '../../utils/kBtoBytes';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { CampaignProps } from '../../interface/campaign/Campaign';
import { useSelector } from 'react-redux';
import { clearState, createCampaign, fetchSingleCampaign, selectCampaign } from './campaignSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { useAppDispatch } from '../../redux/store';

const CloneMailing: React.FC = () => {
  let navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const { entities: campaign, status } = useSelector(selectCampaign);

  const initialValues = {
    calendarName: '',
    anonymousUrl: '',
    anonymousUrlMobile: '',
    mailing: '',
  };

  const [file, setFile] = useState('');
  const [values, setValues] = useState<CampaignProps>(initialValues);

  useEffect(() => {
    if (!params.id) return;
    dispatch(fetchSingleCampaign(params.id!));
  }, [params, dispatch]);

  useEffect(() => {
    if (status.fetchSingleCampaign === fetchStatuses.succeeded) setValues(campaign);
  }, [status, campaign]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleAddFile = (e: any) => {
    const file = e.target.files[0];

    if (file.size > kBtoBytes(500)) {
      toast.error('Rozmiar pliku jest za duży. Maksymalny rozmiar pliku to 500kB');
      return;
    }

    setFile(file.name);
    let reader = new FileReader();

    reader.onload = function (e: any) {
      setValues({
        ...values,
        mailing: e.target.result,
      });
    };

    reader.readAsBinaryString(file);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const { ...campaign } = values;
    dispatch(createCampaign(campaign));
  };

  useEffect(() => {
    if (status.createCampaign === fetchStatuses.succeeded) {
      dispatch(clearState());
      if (values.script) navigate(`/script/${values.script._id}/clone/${campaign._id}`);
      else navigate(`/script/create/${campaign._id}`);
    }
  }, [status, navigate, campaign, values, dispatch]);

  const handleGoBack = () => {
    setValues(initialValues);
    navigate('/');
  };

  return (
    <Flex
      bg='#ffffff'
      direction={'column'}
      alignItems={'center'}
      rounded='lg'
      boxShadow='xl'
      overflow={'auto'}
      p={6}
      pb={10}
      w='80%'
      h='100%'
    >
      <Heading size={'md'} textTransform='uppercase' pb={5}>
        klonowanie mailingu
      </Heading>
      <S.Form onSubmit={(e) => onSubmit(e)}>
        <Flex direction='column' flex={1} overflow='auto' maxW='900px' w='100%' p={6} pr={10} gap={4}>
          <FormControl>
            <FormLabel htmlFor='calendarName'>Nazwa kalendarza</FormLabel>
            <Input
              type='text'
              required
              name='calendarName'
              id='calendarName'
              value={values.calendarName}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Zaniminizowany URL</FormLabel>
            <Input
              type='text'
              required
              value={values.anonymousUrl}
              name='anonymousUrl'
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Zaniminizowany URL Mobilny (opcjonalny)</FormLabel>
            <Input
              type='text'
              value={values.anonymousUrlMobile}
              name='anonymousUrlMobile'
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor='file' pb={2}>
              Zmień plik html
            </FormLabel>
            <HStack p={0} m={0} gap={4} justifyContent={'flex-start'}>
              <S.StyledFileInput
                type='file'
                name='file'
                id='file'
                accept='text/html'
                onChange={(e: any) => handleAddFile(e)}
              />
              <S.StyledLabel htmlFor='file'>Zmień plik</S.StyledLabel>
              <Text> {file}</Text>
            </HStack>
          </FormControl>
          <FormControl>
            <FormLabel>Oryginalny HTML</FormLabel>
            <Flex flex={1} minH='200px' position='relative' data-color-mode='dark'>
              <CodeEditor
                value={values.mailing}
                name='mailing'
                language='js'
                padding={15}
                onChange={handleInputChange}
                style={{
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                  fontSize: 12,
                  borderRadius: '0.375rem',
                  width: '100%',
                }}
              />
            </Flex>
          </FormControl>
          <Flex justifyContent='flex-end'>
            <Flex maxW='500px' w='100%' justifyContent={'flex-end'}>
              <ButtonGroup spacing={6}>
                <Button colorScheme='red' onClick={() => handleGoBack()}>
                  Wróć
                </Button>
                <Button colorScheme='teal' type='submit'>
                  Zapisz
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>
        </Flex>
      </S.Form>
    </Flex>
  );
};

export default CloneMailing;
