import { Divider, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { Creation } from '../../interface/campaign/Creation';
import { CardCodeEditor } from '../codeEditor/CardCodeEditor';

interface CreationProps {
  creations: Creation;
}

const SingleCampaignCreationCard = ({ creations }: CreationProps) => {
  return (
    <>
      <Text pb={3} pt={5} fontSize='18px' fontWeight={'bold'} color={'blue.700'}>
        Kreacje
      </Text>
      <Divider />
      <Tabs size='md' variant='enclosed' py={4} minH={600}>
        <TabList>
          <Tab>Oryginalny HTML</Tab>
          <Tab>Wynikowy HTML</Tab>
          <Tab>Zanonimizowany HTML</Tab>
          <Tab>Skrypt</Tab>
        </TabList>
        <TabPanels minH={500}>
          <TabPanel minH={500}>
            <CardCodeEditor value={creations.originalHtml} language='markup' />
          </TabPanel>
          <TabPanel minH={500}>
            <CardCodeEditor value={creations.resultHtml} language='markup' />
          </TabPanel>
          <TabPanel minH={500}>
            <CardCodeEditor value={creations.anonymousHtml} language='markup' />
          </TabPanel>
          <TabPanel minH={500}>
            <CardCodeEditor value={creations.script} language='js' />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default SingleCampaignCreationCard;
