import { SettingsProps } from '../interface/settings/Settings';
import api from './api';

class SettingsService {
  static async getSettings(): Promise<SettingsProps> {
    const res = await api.get(`/settings`);
    return res.data;
  }

  static async updateSettings(newSettings: SettingsProps): Promise<SettingsProps> {
    const res = await api.put('/settings', newSettings);
    return res.data;
  }

  static async restartCrawlers(): Promise<void> {
    await api.post('/crawlers/restart');
  }

  static async clearQueues(): Promise<void> {
    await api.post('/crawlers/clear-queues');
  }
}

export default SettingsService;
