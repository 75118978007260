import { Heading, HStack, Text, VStack } from '@chakra-ui/react';

interface CallsTabInterface {
  title: string;
  toDo: number;
  done: number;
}

export const CallsTab: React.FC<CallsTabInterface> = ({ title, toDo, done }) => {
  return (
    <>
      <Heading fontSize='md' paddingTop='1rem' textAlign='center'>
        {title}
      </Heading>
      <HStack gap={5} py={4} alignItems={'flex-start'}>
        <VStack w={'33.3%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Do zrealizowania
          </Text>
          <HStack>
            <Text fontSize='16px'>{toDo}</Text>
          </HStack>
        </VStack>
        <VStack w={'33.3%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Zrealizowane
          </Text>
          <HStack>
            <Text fontSize='16px'>{done}</Text>
          </HStack>
        </VStack>
        <VStack w={'33.3%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Pozostałe
          </Text>
          <HStack>
            <Text fontSize='16px'>{toDo - done}</Text>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
};
