import { ScriptProps } from './../interface/script/Script';
import { isInRange } from './isInRange';

const ValidateScript = (script: ScriptProps): string[] => {
  const errors: string[] = [];

  if (!isInRange(script.bounceRate)) {
    errors.push('Bounce rate must be between 0 and 1. ');
  }
  if (!isInRange(script.clickToOpenRate)) {
    errors.push('Click to open rate must be between 0 and 1. ');
  }
  if (!isInRange(script.conversionRate, 0, 100)) {
    errors.push('Conversion rate must be between 0 and 100. ');
  }

  return errors;
};

export default ValidateScript;
