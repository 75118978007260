import CalculatePercent from './calculatePercent';
import { ScriptProps } from '../interface/script/Script';

export const calculateActionCompletion = (script: ScriptProps) => {
  if (script && script.callsFull! >= 0 && script.callsBounced! >= 0 && script?.calls! >= 0) {
    const allActions = script.callsFull! + script.callsBounced!;
    return `${CalculatePercent(allActions, script?.calls!)}% - ${allActions} z ${script?.calls}`;
  }
  return '';
};
