import { ScriptProps } from '../interface/script/Script';
import api from './api';

class ScriptService {
  static async createScript(script: ScriptProps) {
    const res = await api.post(`/scripts`, script);

    return res.data;
  }

  static async getSingleScript(scriptId: string) {
    const res = await api.get(`/scripts/${scriptId}`);

    return res.data;
  }

  static async editScript(script: ScriptProps, scriptId: string) {
    const res = await api.patch(`/scripts/${scriptId}`, script);

    return res.data;
  }

  static async getLogs(id: string) {
    const res = await api.get(`/scripts/${id}/logs`);

    return res.data;
  }

  static async sendTestScript(id: string) {
    const res = await api.post(`/crawlers/script/${id}/test`);

    return res.data;
  }

  static async getScriptsForResources() {
    const res = await api.get(`/scripts/resources`);

    return res.data;
  }

  static async stopAllScripts() {
    const res = await api.post(`/scripts/stop`);

    return res.data;
  }
}

export default ScriptService;
