import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import { login, selectAuth } from './authSlice';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { useAppDispatch } from '../../redux/store';

const Login = () => {
  const dispatch = useAppDispatch();
  const { status } = useSelector(selectAuth);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<boolean>();

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    dispatch(
      login({
        email,
        password,
      })
    );
  };

  useEffect(() => {
    if (status === fetchStatuses.failed) setLoginError(true);
  }, [status, navigate]);

  useEffect(() => {
    setLoginError(false);
  }, [email, password]);

  return (
    <>
      <Heading fontSize={'4xl'} textAlign={'center'} minW={'80'}>
        Zaloguj się
      </Heading>
      <Stack spacing={4}>
        <FormControl id='email' isInvalid={loginError}>
          <FormLabel>E-mail</FormLabel>
          <Input
            type='email'
            required
            onChange={(e) => {
              setEmail(e.target.value);
              setLoginError(false);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />
        </FormControl>
        <FormControl id='password' isInvalid={loginError}>
          <FormLabel>Hasło</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              required
              onChange={(e) => {
                setPassword(e.target.value);
                setLoginError(false);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          {loginError && <FormErrorMessage>Logowanie nie powiodło się. Spróbuj ponownie.</FormErrorMessage>}
        </FormControl>
        <Stack spacing={10}>
          <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
            <Checkbox>Zapamiętaj mnie</Checkbox>
            <Link as={RouterLink} to='/reset-password-link' color={'teal.500'}>
              Zapomniałem hasła
            </Link>
          </Stack>
          <Button
            onClick={handleLogin}
            bg={'teal.300'}
            color={'white'}
            _hover={{
              bg: 'teal.500',
            }}
          >
            Zaloguj
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Login;
