import { Box, Table, Tbody, Td, Th, Thead, Tr, Text, Heading, Flex, Spinner } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScriptForResources, selectScript } from '../script/scriptSlice';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { ScriptProps } from '../../interface/script/Script';
import { calculateLeftClicks } from '../../utils/calculateLeftClicks';
import { fetchSettings, selectSettings } from '../settings/settingsSlice';

const Resources: React.FC = () => {
  const dispatch = useDispatch();
  const { entities, status } = useSelector(selectScript);
  const { entities: settings, status: settingsStatus } = useSelector(selectSettings);
  const [resources, setResources] = React.useState<[ScriptProps[], ScriptProps[], ScriptProps[]]>([
    [],
    [],
    [],
  ]);

  useEffect(() => {
    dispatch(fetchScriptForResources());
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (status.fetchScriptForResources === fetchStatuses.succeeded) {
      const splittedResources: any = [[], [], []];
      entities.forEach((item: ScriptProps) => {
        if (moment(item.start).get('day') === moment().get('day')) {
          splittedResources[0].push(item);
        }
        if (moment(item.start).get('day') === moment().add(1, 'days').get('day')) {
          splittedResources[1].push(item);
        }
        if (moment(item.start).get('day') === moment().add(2, 'days').get('day')) {
          splittedResources[2].push(item);
        }
      });
      setResources(splittedResources);
    }
  }, [status, entities]);

  const calculateClicksSum = (item: ScriptProps[]) => {
    let sum = 0;
    item.forEach((item: ScriptProps) => {
      sum += item.allActions!;
    });

    return Math.ceil(sum);
  };

  return (
    <Box w='100%' h='100%' bg={'whiteAlpha.900'} rounded='md' boxShadow='xl' p={3} overflow='auto'>
      {status.fetchScriptForResources === fetchStatuses.loading ||
      settingsStatus.fetchSettings === fetchStatuses.loading ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <>
          <Heading size={'md'} textTransform='uppercase' textAlign='center' my={3}>
            Dostępne zasoby
          </Heading>
          <Table variant='striped' colorScheme='blackAlpha' size='sm'>
            <Thead>
              <Tr>
                <Th w='20%'>Data</Th>
                <Th w='20%'>Ilość zaplanowanych kampanii</Th>
                <Th w='30%'>Zaplanowane kliknięcia</Th>
                <Th w='30%'>Możliwe kliknięcia do dodania za 20min</Th>
              </Tr>
            </Thead>
            <Tbody>
              {resources &&
                resources.length > 0 &&
                resources.map((item: ScriptProps[], idx: number) => {
                  const clicksSum = calculateClicksSum(item);
                  const clicksIn20Min = calculateLeftClicks(
                    settings.endGateway + 1,
                    0,
                    idx === 0
                      ? moment().add(20, 'minutes').format()
                      : moment().add(idx, 'day').startOf('day').format()
                  );
                  return (
                    <Tr key={idx} height='41px'>
                      <Td>
                        <Text fontSize='lg'>{moment().add(idx, 'days').format('YYYY-MM-DD')}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.length.toString()}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{clicksSum.toString()}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{clicksIn20Min.toString()}</Text>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default Resources;
