import { ChakraProvider, theme } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import { authenticate } from './features/auth/authSlice';
import AppRoutes from './routes/Routes';
import 'react-datepicker/dist/react-datepicker.css';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticate());
  }, [dispatch]);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <MainLayout>
          <AppRoutes />
        </MainLayout>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
