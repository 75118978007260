import { Box, VStack, Accordion, Icon, Button } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineBug } from 'react-icons/ai';
import MailingAccordionItem from './MailingAccordionItem';
import AdminButtonLink from './AdminButtonLink';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import GatewaysButtonLink from './GatewaysButtonLink';

const SideNav: React.FC = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logout());
  };

  return (
    <Box w='100%' h='100%' bg='#ffffff' rounded='md' boxShadow='lg' overflow='auto'>
      <VStack p={2} pt={10}>
        <Icon as={AiOutlineBug} w={10} h={10} mb={8} />
        <Button colorScheme='teal' onClick={handleLogout} w={'60%'}>
          Wyloguj
        </Button>
        <Accordion allowToggle w={'100%'} pt={8}>
          <MailingAccordionItem />
          <AdminButtonLink />
          <GatewaysButtonLink />
        </Accordion>
      </VStack>
    </Box>
  );
};

export default SideNav;
