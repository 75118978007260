import api from './api';
import { CampaignProps } from '../interface/campaign/Campaign';
import { CampaignQueryParams } from '../interface/campaign/CamapignQueryParams';

class CampaignService {
  static async getAllCampaigns(campaignQueryParams?: CampaignQueryParams): Promise<[]> {
    const params = { ...campaignQueryParams };

    const res = await api.get(`/campaigns`, { params });

    return res.data;
  }

  static async getSingleCampaign(campaignId: string) {
    const res = await api.get(`/campaigns/${campaignId}`);

    return res.data;
  }

  static async createCampaign(campaign: CampaignProps): Promise<CampaignProps> {
    const res = await api.post(`/campaigns`, campaign);

    return res.data;
  }

  static async editCampaign(campaign: CampaignProps, campaignId: string) {
    const res = await api.patch(`/campaigns/${campaignId}`, campaign);

    return res.data;
  }

  static async deleteCampaign(campaignId: string) {
    const res = await api.delete(`/campaigns/${campaignId}`);

    return res.data;
  }
}

export default CampaignService;
