import axios from 'axios';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/refresh' && err.response) {
      if (
        (err.response.status === 401 || err.response.status === 403) &&
        originalConfig.url !== '/auth/login'
      ) {
        try {
          await instance.get('/auth/refresh');
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else {
        toast.error(err.response.data.messageForUser);
      }
    }
    return Promise.reject(err);
  }
);
export default instance;
