import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import ScriptService from '../../services/scriptService';
import { ScriptProps } from '../../interface/script/Script';
import { toast } from 'react-toastify';

const initialState: GlobalState = {
  entities: {},
  status: {},
  error: false,
};

export const createScript = createAsyncThunk(
  'script/createScript',
  async (script: ScriptProps): Promise<ScriptProps> => {
    const result = await ScriptService.createScript(script);
    return result;
  }
);

export const editScript = createAsyncThunk(
  'script/editScript',
  async ({ script, id }: { script: ScriptProps; id: string }): Promise<ScriptProps> => {
    const result = await ScriptService.editScript(script, id);
    return result;
  }
);

export const fetchSingleScript = createAsyncThunk(
  'script/fetchSingleScript',
  async (id: string): Promise<ScriptProps> => {
    const result = await ScriptService.getSingleScript(id);
    return result;
  }
);

export const fetchScriptLogs = createAsyncThunk(
  'script/fetchScriptLogs',
  async (id: string): Promise<ScriptProps> => {
    const result = await ScriptService.getLogs(id);
    return result;
  }
);

export const sendTestScript = createAsyncThunk('script/sendTestScript', async (id: string) => {
  const result = await ScriptService.sendTestScript(id);
  return result;
});

export const fetchScriptForResources = createAsyncThunk(
  'script/fetchScriptForResources',
  async (): Promise<ScriptProps[]> => {
    const result = await ScriptService.getScriptsForResources();
    return result;
  }
);

export const stopAllScripts = createAsyncThunk('script/stopAllScripts', async () => {
  const result = await ScriptService.stopAllScripts();
  return result;
});

const scriptSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSingleScript.pending, (state, action) => {
        state.status = { ...state.status, fetchSingleScript: fetchStatuses.loading };
      })
      .addCase(fetchSingleScript.fulfilled, (state, action) => {
        state.status = { ...state.status, fetchSingleScript: fetchStatuses.succeeded };
        state.entities = action.payload;
      })
      .addCase(createScript.fulfilled, (state, action) => {
        state.status = { ...state.status, createScript: fetchStatuses.succeeded };
        toast.success('Pomyślnie dodano skrypt');
      })
      .addCase(editScript.fulfilled, (state, action) => {
        state.status = { ...state.status, editScript: fetchStatuses.succeeded };
        toast.success('Edycja skryptu przebiegła pomyślnie');
      })
      .addCase(fetchScriptLogs.pending, (state, action) => {
        state.status = { ...state.status, fetchScriptLogs: fetchStatuses.loading };
      })
      .addCase(fetchScriptLogs.fulfilled, (state, action) => {
        state.status = { ...state.status, fetchScriptLogs: fetchStatuses.succeeded };
        state.entities = action.payload;
      })
      .addCase(sendTestScript.fulfilled, (state, action) => {
        toast.success('Wysłano testowy skrypt');
      })
      .addCase(fetchScriptForResources.pending, (state, action) => {
        state.status = { ...state.status, fetchScriptForResources: fetchStatuses.loading };
      })
      .addCase(fetchScriptForResources.fulfilled, (state, action) => {
        state.status = { ...state.status, fetchScriptForResources: fetchStatuses.succeeded };
        state.entities = action.payload;
      })
      .addCase(stopAllScripts.pending, (state, action) => {
        state.status = { ...state.status, stopAllScripts: fetchStatuses.loading };
      })
      .addCase(stopAllScripts.fulfilled, (state, action) => {
        state.status = { ...state.status, stopAllScripts: fetchStatuses.succeeded };
      });
  },
});

export const selectScript = (state: any) => state.script;
export const { clearState } = scriptSlice.actions;

export default scriptSlice.reducer;
