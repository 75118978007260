import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  HStack,
  Spinner,
  Flex,
  Text,
  FormLabel,
  FormControl,
  Select,
  Heading,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import FormatDate from '../../utils/formatDate';
import CalculatePercent from '../../utils/calculatePercent';
import { CampaignProps } from '../../interface/campaign/Campaign';
import { CampaignQueryFilters, CampaignQueryParams } from '../../interface/campaign/CamapignQueryParams';
import { deleteCampaign, fetchCampaigns, selectCampaigns } from './campaignsSlice';
import TablePagination from '../../components/pagination/TablePagination';
import { sendTestScript } from '../script/scriptSlice';
import * as S from '../../components/sharedStyles/SharedStyles.Elements';
import { calculateLeftClicks } from '../../utils/calculateLeftClicks';
import { calculateActionCompletion } from '../../utils/calculateActionCompletion';
import ConfirmationModal from '../../components/modals/ConfirmationModal';

const MailingCampaignList: React.FC = () => {
  let navigate = useNavigate();
  const { entities: campaigns, status } = useSelector(selectCampaigns);
  const dispatch = useDispatch();

  const [campaignQueryParams, setCampaignQueryParams] = useState<CampaignQueryParams>({
    limit: 50,
    page: 1,
    sort: '-script.start',
  });
  const [filtersVisible, setFiltersVisible] = useState(true);

  const [campaignQueryFilters, setCampaignQueryFilters] = useState<CampaignQueryFilters>({
    calendarName: '',
    anonymousUrl: '',
  });

  const modalActions = useDisclosure();
  const [itemToDelete, setItemToDelete] = useState<string>('');

  const returnIcon = (active: boolean) => {
    return active ? <CheckIcon color={'green.400'} /> : <CloseIcon color={'red.400'} />;
  };

  const updatePaginationValues = (paginationValues: { limit: number; page: number }) => {
    setCampaignQueryParams({
      ...campaignQueryParams,
      ...paginationValues,
    });
  };

  const toggleFiltersVisibility = () => {
    setFiltersVisible(!filtersVisible);
  };

  const handleQueryParamsChange = (e: any) => {
    const { value, name } = e.target;

    setCampaignQueryParams({
      ...campaignQueryParams,
      [name]: value,
    });
  };

  const handleFilterParamsChange = (e: any) => {
    const { value, name } = e.target;

    setCampaignQueryFilters({
      ...campaignQueryFilters,
      [name]: value,
    });
  };

  const onFiltersSubmit = (e: any) => {
    e.preventDefault();

    setCampaignQueryParams({
      ...campaignQueryParams,
      filters: {
        ...(campaignQueryFilters.calendarName && { calendarName: campaignQueryFilters.calendarName }),
        ...(campaignQueryFilters.anonymousUrl && { anonymousUrl: campaignQueryFilters.anonymousUrl }),
      },
    });
  };

  const clearCampaignQueryFilters = () => {
    setCampaignQueryFilters({
      calendarName: '',
      anonymousUrl: '',
    });
    setCampaignQueryParams({
      ...campaignQueryParams,
      filters: {},
    });
  };

  const setElementToDelete = (element: string) => {
    modalActions.onOpen();
    setItemToDelete(element);
  };

  const confirmModal = () => {
    dispatch(deleteCampaign(itemToDelete));
    modalActions.onClose();
  };

  useEffect(() => {
    dispatch(fetchCampaigns(campaignQueryParams));
  }, [dispatch, campaignQueryParams]);

  useEffect(() => {
    if (status.deleteCampaign === fetchStatuses.succeeded) {
      dispatch(fetchCampaigns(campaignQueryParams));
    }
  }, [dispatch, status, campaignQueryParams]);

  return (
    <Box
      w='100%'
      h='100%'
      bg={'whiteAlpha.900'}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      rounded='md'
      boxShadow='xl'
      p={3}
      overflow='auto'
    >
      {status.fetchCampaigns === fetchStatuses.loading ? (
        <Flex minH='100vh' w='100%' align='center' justify='center'>
          <Spinner size='xl' />
        </Flex>
      ) : (
        <Flex direction='column'>
          <Heading size={'md'} textTransform='uppercase' textAlign='center' my={3}>
            Lista mailingów
          </Heading>
          <Flex my='1rem' direction='row' justifyContent='space-between' align='end'>
            <FormControl width='auto'>
              <FormLabel>Sortowanie:</FormLabel>
              <Select
                name='sort'
                id='sort'
                value={campaignQueryParams.sort}
                onChange={handleQueryParamsChange}
                borderColor='gray'
              >
                <option value='-script.start'>Najnowsze</option>
                <option value='script.start'>Najstarsze</option>
                <option value='calendarName'>Nazwa - A do Z</option>
                <option value='-calendarName'>Nazwa - Z do A</option>
              </Select>
            </FormControl>
            {filtersVisible && (
              <form onSubmit={(e) => onFiltersSubmit(e)}>
                <Flex alignItems={'flex-end'} gap={2}>
                  <FormControl>
                    <FormLabel>Nazwa kampanii: </FormLabel>
                    <Input
                      type='text'
                      name='calendarName'
                      id='calendarName'
                      value={campaignQueryFilters?.calendarName}
                      onChange={handleFilterParamsChange}
                      borderColor='gray'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Zanonimizowany URL: </FormLabel>
                    <Input
                      name='anonymousUrl'
                      id='anonymousUrl'
                      value={campaignQueryFilters?.anonymousUrl}
                      onChange={handleFilterParamsChange}
                      borderColor='gray'
                    />
                  </FormControl>
                  <Button colorScheme='green' type='submit' w='180px'>
                    Filtruj
                  </Button>
                  {(campaignQueryParams.filters?.anonymousUrl ||
                    campaignQueryParams.filters?.calendarName) && (
                    <Button type='button' colorScheme='red' onClick={clearCampaignQueryFilters} w='180px'>
                      Usuń filtry
                    </Button>
                  )}
                </Flex>
              </form>
            )}
            <Button colorScheme='teal' onClick={toggleFiltersVisibility}>
              {!filtersVisible ? 'Pokaż' : 'Schowaj'} filtry
            </Button>
          </Flex>
          <Table variant='striped' colorScheme='blackAlpha' size='sm' mt='2rem'>
            <Thead>
              <Tr>
                <Th w='25%'>Nazwa</Th>
                <Th w='10%'>Data startu</Th>
                <Th w='5%'>skrypt aktywny</Th>
                <Th w='10%'>Realizacja akcji</Th>
                <Th w='10%'>Realizacja otwarć i akcji</Th>
                <Th w='10%'>Kliknięcia w kolejce</Th>
                <Th w='10%'>Kliknięcia możliwe do dodania</Th>
                <Th w='20%'>Operacje</Th>
              </Tr>
            </Thead>
            <Tbody>
              {campaigns.items &&
                campaigns.items.map((item: CampaignProps) => {
                  const gatewaysLength = item.script?.endGateway! + 1 - item.script?.startGateway!;
                  return (
                    <Tr key={item._id}>
                      <Td>
                        <Text fontSize='lg'>{item.calendarName}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.script ? FormatDate(item.script?.start, ' ') : ''}</Text>
                      </Td>
                      <Td textAlign={'center'}>{returnIcon(item.script?.isActive ?? false)}</Td>
                      <Td>
                        <Text fontSize='lg'>{item.script && calculateActionCompletion(item.script)}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>
                          {item.script && item.script.callsDone! >= 0 && item.script?.allActions! >= 0
                            ? `${CalculatePercent(item.script?.callsDone!, item.script?.allActions!)}% - ${
                                item.script?.callsDone
                              } z ${item.script?.allActions}`
                            : ''}
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>{item.script ? item.script.queuedClicks! : ''}</Text>
                      </Td>
                      <Td>
                        <Text fontSize='lg'>
                          {item.script && item.script?.allActions!
                            ? calculateLeftClicks(gatewaysLength, item.script?.allActions!, item.script.start)
                            : ''}
                        </Text>
                      </Td>
                      <Td>
                        <HStack minW='250px' flexWrap={'wrap'} gap='3px'>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'red'}
                            onClick={() => navigate(`/campaigns/${item._id}`)}
                          >
                            Pokaż
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'yellow'}
                            onClick={() => navigate(`/mailing/${item._id}/edit`)}
                          >
                            Mailing
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'teal'}
                            onClick={() => {
                              if (item.script && Object.keys(item.script).length > 0)
                                navigate(`/script/${item.script!._id!}/edit`);
                              else {
                                navigate(`/script/create/${item._id!}`);
                                toast.info('Kampania nie posiada skryptu - stwórz nowy');
                              }
                            }}
                          >
                            Skrypt
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'blue'}
                            onClick={() => navigate(`/mailing/${item._id}/clone`)}
                          >
                            Klonuj
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'green'}
                            onClick={() => {
                              if (item.script && Object.keys(item.script).length > 0) {
                                navigate(`/echos/create/${item._id!}`);
                                toast.info('Tworzenie nowego echa');
                              } else {
                                toast.error('Kampania nie posiada skryptu - nie możesz stworzyć echa');
                              }
                            }}
                          >
                            Echo
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'teal'}
                            onClick={() => {
                              if (
                                item.script &&
                                Object.keys(item.script).length > 0 &&
                                item.script.callsDone! > 0
                              ) {
                                navigate(`/script/${item.script!._id!}/logs`);
                              } else {
                                toast.error('Kampania nie posiada jeszcze logów');
                              }
                            }}
                          >
                            Logi
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'yellow'}
                            onClick={() => {
                              if (item.script && Object.keys(item.script).length > 0)
                                dispatch(sendTestScript(item.script!._id!));
                            }}
                          >
                            Test
                          </S.DashboardButton>
                          <S.DashboardButton
                            size={'xs'}
                            colorScheme={'red'}
                            onClick={() => setElementToDelete(item._id!)}
                          >
                            Usuń
                          </S.DashboardButton>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          <ConfirmationModal
            modalOnConfirm={confirmModal}
            modalActions={{
              isOpen: modalActions.isOpen,
              onOpen: modalActions.onOpen,
              onClose: modalActions.onClose,
            }}
          ></ConfirmationModal>
        </Flex>
      )}
      {campaigns.items && (
        <TablePagination
          propsPaginationValues={{
            limit: campaignQueryParams.limit,
            page: campaignQueryParams.page,
          }}
          quantity={campaigns.count}
          updatePaginationValues={updatePaginationValues}
        ></TablePagination>
      )}
    </Box>
  );
};

export default MailingCampaignList;
