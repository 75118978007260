import { Button, useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { stopAllScripts } from '../../script/scriptSlice';

const StopAllScripts = () => {
  const dispatch = useDispatch();

  const modalActions = useDisclosure();
  const confirmModal = async () => {
    dispatch(stopAllScripts());
    modalActions.onClose();
  };

  return (
    <>
      <Button onClick={modalActions.onOpen} colorScheme='teal' me='1rem' size='lg'>
        Zatrzymaj wszystkie skrypty
      </Button>
      <ConfirmationModal
        modalActions={{
          isOpen: modalActions.isOpen,
          onOpen: modalActions.onOpen,
          onClose: modalActions.onClose,
        }}
        modalOnConfirm={confirmModal}
      />
    </>
  );
};

export default StopAllScripts;
