import { Flex, Input, Textarea, Text, Button } from '@chakra-ui/react';
import styled from 'styled-components';
import ViewBox from '../layout/ViewBox';
import DatePicker from 'react-datepicker';

export const AddMailingWrapper = styled(ViewBox)`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
`;

export const AddScriptWrapper = styled(AddMailingWrapper)``;

export const StyledFlex = styled(Flex)`
  gap: 20px;
  position: relative;
`;

export const StyledInput = styled(Input)`
  height: 42px;
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #5f5f5f;

  &:hover {
    border-color: #008040;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px teal;
  }
`;

export const StyledTexarea = styled(Textarea)`
  background-color: #ffffff;
  flex: 1;
  border: 1px solid #5f5f5f;

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2px teal;
  }
`;

export const StyledText = styled(Text)`
  font-size: 14px;
  max-width: 200px;
  width: 100%;
  text-align: right;
`;

export const StyledHint = styled(Text)`
  font-size: 13px;
  position: absolute;
  left: 220px;
  bottom: 0px;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

export const StyledFileInput = styled(Input)`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const StyledLabel = styled.label`
  padding: 8px 16px;
  border-radius: 10px;
  color: #ffffff;
  background-color: rgba(0, 128, 128, 0.7);
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 128, 128, 1);
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  padding: 0.25rem;
  cursor: pointer;
`;

export const DashboardButton = styled(Button)`
  margin: 0 !important;
`;
