import CodeEditor from '@uiw/react-textarea-code-editor';
import styled from 'styled-components';

interface EditableCodeEditorProps {
  value: string;
  language: string;
  handleChangeCode: (newCode: string) => void;
}

const CodeEditorWrapper = styled.div`
  width: 100%;
  min-height: 200px;
`;

export const EditableCodeEditor = ({ value, language, handleChangeCode }: EditableCodeEditorProps) => {
  return (
    <CodeEditorWrapper data-color-mode='dark'>
      <CodeEditor
        value={value}
        language={language}
        padding={15}
        onChange={(e) => handleChangeCode(e.target.value)}
        style={{
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
          fontSize: 12,
          borderRadius: '0.375rem',
          minWidth: '100%',
          minHeight: '200px',
        }}
      />
    </CodeEditorWrapper>
  );
};
