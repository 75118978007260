import api from './api';
import { CreateEchoProps } from '../interface/echo/CreateEcho';
import { EchoQueryParams } from '../interface/echo/EchoQueryParams';

class EchoService {
  static async getAllEchos(echoQueryParams?: EchoQueryParams): Promise<[]> {
    const params = { ...echoQueryParams };

    const res = await api.get(`/echos`, { params });

    return res.data;
  }

  static async getSingleEcho(echoId: string) {
    const res = await api.get(`/echos/${echoId}`);

    return res.data;
  }

  static async createEcho(echo: CreateEchoProps) {
    const res = await api.post(`/echos`, echo);

    return res.data;
  }

  static async editEcho(echo: CreateEchoProps, echoId: string) {
    const res = await api.patch(`/echos/${echoId}`, echo);

    return res.data;
  }
}

export default EchoService;
