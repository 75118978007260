import { Box, Flex } from '@chakra-ui/react';
import SideNav from '../SideNav/SideNav';

const MainView = ({ children }: JSX.ElementChildrenAttribute) => {
  return (
    <Box minH='100vh' w='100%' bg='#002529'>
      <Flex>
        <Box w='250px' h='100vh' p={4}>
          <SideNav />
        </Box>
        <Flex flex={1} direction='column' h='100vh' gap='10px'>
          <Flex flex={1} h='100%' justifyContent='center' pt={4} pr={4} pb={4}>
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MainView;
