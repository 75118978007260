import { Box, ButtonGroup, Button, Divider, HStack, VStack, Text, Heading } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendTestScript } from '../../features/script/scriptSlice';
import FormatDate from '../../utils/formatDate';
import { returnIcon } from '../../utils/returnAvailabilityIcon';
import { CallsTab } from './CallsTab';

interface ScriptDataProps {
  scriptData: {
    startDate: string;
    bounceRate: number;
    clickToOpenRate: number;
    conversionRate: number;
    activeScript: boolean;
    mobileScript: boolean;
    desktopScript: boolean;
    allActions: number;
    openActions: number;
    fullActions: number;
    bounceActions: number;
    callsDone: number;
    callsOpen: number;
    callsFull: number;
    callsBounced: number;
    callsFailed: number;
    id: string;
  };
}

const SingleCampaignScriptCard = ({ scriptData }: ScriptDataProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      h={'100%'}
      w={'50%'}
      bg={'white'}
      rounded={'lg'}
      boxShadow={'lg'}
      p={6}
      position={'relative'}
      overflow={'auto'}
    >
      <ButtonGroup position={'absolute'} top={'24px'} right={'24px'}>
        <Button
          size={'sm'}
          colorScheme={'yellow'}
          onClick={() => {
            dispatch(sendTestScript(scriptData.id));
          }}
        >
          test
        </Button>
        <Button
          size={'sm'}
          colorScheme={'red'}
          onClick={() => {
            navigate(`/script/${scriptData.id}/edit`);
          }}
        >
          edytuj
        </Button>
      </ButtonGroup>
      <Text pb={3} fontSize='18px' fontWeight={'bold'} color={'blue.700'}>
        Skrypt
      </Text>
      <Divider />
      <HStack gap={5} py={4} alignItems={'flex-start'}>
        <VStack w={'50%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Początek działania
          </Text>
          <HStack>
            <Text fontSize='16px'>{FormatDate(scriptData.startDate, ' ')}</Text>
          </HStack>
        </VStack>
        <VStack w={'50%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Aktywny
          </Text>
          <HStack>{returnIcon(scriptData.activeScript)}</HStack>
          <Text fontWeight={'bold'} fontSize='14px'>
            Tylko mobilne urządzenia
          </Text>
          <HStack>{returnIcon(scriptData.mobileScript)}</HStack>
          <Text fontWeight={'bold'} fontSize='14px'>
            Tylko desktopowe urządzenia
          </Text>
          <HStack>{returnIcon(scriptData.desktopScript)}</HStack>
        </VStack>
      </HStack>
      <Divider />
      <HStack gap={5} py={4} alignItems={'flex-start'}>
        <VStack w={'50%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Przeciętny Bounce Rate
          </Text>
          <HStack>
            <Text fontSize='16px'>{scriptData.bounceRate}</Text>
          </HStack>
        </VStack>
        <VStack w={'50%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Przeciętny Clik To Open Rate
          </Text>
          <Text fontSize='16px'>{scriptData.clickToOpenRate}</Text>
        </VStack>
      </HStack>
      <Divider />
      <HStack gap={5} py={4} alignItems={'flex-start'}>
        <VStack w={'100%'}>
          <Text fontWeight={'bold'} fontSize='14px' textAlign={'center'}>
            Dopuszczalna % różnica pomiędzy próbami wywołania skryptu a jego sukcesem
          </Text>
          <HStack>
            <Text fontSize='16px'>{scriptData.conversionRate}</Text>
          </HStack>
        </VStack>
      </HStack>
      <Divider />
      <CallsTab title='Wszystkie wywołania' toDo={scriptData.allActions} done={scriptData.callsDone} />
      <Divider />
      <CallsTab title='Wywołania open' toDo={scriptData.openActions} done={scriptData.callsOpen} />
      <Divider />
      <CallsTab title='Wywołania full' toDo={scriptData.fullActions} done={scriptData.callsFull} />
      <Divider />
      <CallsTab title='Wywołania bounce' toDo={scriptData.bounceActions} done={scriptData.callsBounced} />
      <Divider />
      <Heading fontSize='md' paddingTop='1rem' textAlign='center' color={'red.500'}>
        Wywołania nieudane
      </Heading>
      <HStack gap={5} py={4}>
        <VStack w={'100%'}>
          <HStack>
            <Text fontSize='16px' color={'red.500'}>
              {scriptData.callsFailed}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      <Divider />
    </Box>
  );
};

export default SingleCampaignScriptCard;
