import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { EchoProps } from '../../interface/echo/Echo';
import EchoService from '../../services/echoService';
import { CreateEchoProps } from '../../interface/echo/CreateEcho';
import { toast } from 'react-toastify';

const initialState: GlobalState = {
  entities: {},
  status: {},
  error: false,
};

export const fetchSingleEcho = createAsyncThunk(
  'echo/fetchSingleEcho',
  async (id: string): Promise<EchoProps> => {
    const echos = await EchoService.getSingleEcho(id);
    return echos;
  }
);

export const createEcho = createAsyncThunk(
  'echo/createEcho',
  async (echoData: CreateEchoProps): Promise<CreateEchoProps> => {
    const result = await EchoService.createEcho(echoData);
    return result;
  }
);

const echoSlice = createSlice({
  name: 'echo',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSingleEcho.pending, (state, action) => {
        state.status = { ...state.status, fetchSingleEcho: fetchStatuses.loading };
      })
      .addCase(fetchSingleEcho.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.status = { ...state.status, fetchSingleEcho: fetchStatuses.succeeded };
      })
      .addCase(createEcho.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.status = { ...state.status, createEcho: fetchStatuses.succeeded };
        toast.success('Echo zostało dodane.');
      });
  },
});

export const selectEcho = (state: any) => state.echo;
export const { clearState } = echoSlice.actions;

export default echoSlice.reducer;
