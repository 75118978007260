import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FaToolbox } from 'react-icons/fa';
import { Icon, Box, Text, Button } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledRouterLink = styled(RouterLink)`
  width: 100%;
  display: flex;
  padding-left: 1rem;
  height: 40px;
  align-items: center;
`;

const AdminButtonLink: React.FC = () => {
  return (
    <Button colorScheme='dark' variant='link' fontWeight='normal'>
      <StyledRouterLink to='/admin'>
        <Icon as={FaToolbox} fontSize='md' />
        <Box flex='1' textAlign='left' pl='3'>
          <Text>Administracja</Text>
        </Box>
      </StyledRouterLink>
    </Button>
  );
};

export default AdminButtonLink;
