import { SettingsProps } from '../interface/settings/Settings';
import { isInRange } from './isInRange';

const ValidateSettings = (settings: SettingsProps): string[] => {
  const errors: string[] = [];

  if (!isInRange(settings.bounceRate, 0, 1)) {
    errors.push('Bounce rate must be between 0 and 1. ');
  }
  if (!isInRange(settings.clickToOpenRate, 0, 1)) {
    errors.push('Click to open rate must be between 0 and 1. ');
  }
  if (!isInRange(settings.conversionRate, 0, 100)) {
    errors.push('Conversion rate must be between 0 and 100. ');
  }
  if (!isInRange(settings.mobileRate, 0, 1)) {
    errors.push('Mobile rate must be between 0 and 1. ');
  }

  const powerDistributionRegex = /^[0-9]\.[0-9]+$/m;
  const splittedPowerDistribution = settings.powerDistribution.split('\n');
  splittedPowerDistribution.forEach((item: string) => {
    if (!powerDistributionRegex.test(item)) {
      errors.push('Power distribution format is wrong. ');
      return;
    }
  });

  return errors;
};

export default ValidateSettings;
