import { Box, Button, Divider, Text, HStack, ButtonGroup, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Creation } from '../../interface/campaign/Creation';
import SingleCampaignCreationCard from './SingleCampaignCreationCard';

interface MailingProps {
  mailing: {
    calendarName: string;
    anonymousUrl: string;
    id: string;
    scriptId: string;
  };
  creations: Creation;
}

const SingleCampaignMailingCard = ({ mailing, creations }: MailingProps) => {
  const navigate = useNavigate();

  return (
    <Box
      h={'100%'}
      w={'50%'}
      bg={'white'}
      rounded={'lg'}
      boxShadow={'lg'}
      p={6}
      position={'relative'}
      overflow='auto'
    >
      <ButtonGroup position={'absolute'} top={'24px'} right={'24px'}>
        {mailing.scriptId.length > 0 && (
          <Button
            size={'sm'}
            colorScheme={'yellow'}
            onClick={() => {
              navigate(`/script/${mailing.scriptId}/logs`);
            }}
          >
            logi
          </Button>
        )}
        <Button
          size={'sm'}
          colorScheme={'red'}
          onClick={() => {
            navigate(`/mailing/${mailing.id}/edit`);
          }}
        >
          edytuj
        </Button>
      </ButtonGroup>
      <Text pb={3} fontSize='18px' fontWeight={'bold'} color={'blue.700'}>
        Mailing
      </Text>
      <Divider />
      <HStack gap={5} py={4} alignItems={'flex-start'}>
        <VStack w={'50%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Nazwa kalendarza
          </Text>
          <HStack>
            <Text fontSize='16px' maxW='22vw'>
              {mailing.calendarName}
            </Text>
          </HStack>
        </VStack>
        <VStack w={'50%'}>
          <Text fontWeight={'bold'} fontSize='14px'>
            Zanonimizowany URL
          </Text>
          <HStack>
            <Text fontSize='16px' maxW='22vw'>
              {mailing.anonymousUrl}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      <Divider />
      <SingleCampaignCreationCard creations={creations} />
    </Box>
  );
};

export default SingleCampaignMailingCard;
