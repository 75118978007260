import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import campaignReducer from '../features/campaign/campaignSlice';
import campaignsReducer from '../features/campaigns/campaignsSlice';
import echoReducer from '../features/echo/echoSlice';
import echosReducer from '../features/echos/echosSlice';
import settingsReducer from '../features/settings/settingsSlice';
import gatewaysReducer from '../features/gateways/gatewaysSlice';
import scriptReducer from '../features/script/scriptSlice';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: {
    auth: authReducer,
    campaign: campaignReducer,
    campaigns: campaignsReducer,
    echo: echoReducer,
    echos: echosReducer,
    settings: settingsReducer,
    gateways: gatewaysReducer,
    script: scriptReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
