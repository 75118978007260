import { CampaignProps } from '../../interface/campaign/Campaign';
import { GlobalState } from '../../interface/redux/globalState';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CampaignService from '../../services/campaignService';
import { fetchStatuses } from '../../static/enums/fetchStatuses';
import { toast } from 'react-toastify';

const initialState: GlobalState = {
  entities: {},
  status: {},
  error: false,
};

export const fetchSingleCampaign = createAsyncThunk(
  'campaign/fetchSingleCampaign',
  async (id: string): Promise<CampaignProps> => {
    const campaign = await CampaignService.getSingleCampaign(id);
    return campaign;
  }
);

export const createCampaign = createAsyncThunk(
  'campaign/createCampaign',
  async (campaign: CampaignProps): Promise<CampaignProps> => {
    const result = await CampaignService.createCampaign(campaign);
    return result;
  }
);

export const editCampaign = createAsyncThunk(
  'campaign/editCampaign',
  async ({ campaign, id }: { campaign: CampaignProps; id: string }): Promise<CampaignProps> => {
    const result = await CampaignService.editCampaign(campaign, id);
    return result;
  }
);

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSingleCampaign.pending, (state, action) => {
        state.status = { ...state.status, fetchSingleCampaign: fetchStatuses.loading };
      })
      .addCase(fetchSingleCampaign.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.status = { ...state.status, fetchSingleCampaign: fetchStatuses.succeeded };
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.status = { ...state.status, createCampaign: fetchStatuses.succeeded };
        toast.success('Kampania została dodana. Możesz teraz dodać skrypt');
      })
      .addCase(editCampaign.fulfilled, (state, action) => {
        state.entities = action.payload;
        state.status = { ...state.status, editCampaign: fetchStatuses.succeeded };
        toast.success('Edycja kampani przebiegła pomyślnie');
      });
  },
});

export const selectCampaign = (state: any) => state.campaign;
export const { clearState } = campaignSlice.actions;

export default campaignSlice.reducer;
