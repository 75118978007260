import { Box, Heading } from '@chakra-ui/react';
import React from 'react';

interface ViewBoxProps {
  viewTitle: string;
  viewWidth: string | number;
  viewHeight: string | number;
}

const ViewBox: React.FC<ViewBoxProps> = ({ viewTitle, children, viewWidth, viewHeight }) => {
  return (
    <Box
      bg='#ffffff'
      rounded='lg'
      boxShadow='xl'
      overflow='auto'
      p={4}
      pb={10}
      width={viewWidth}
      height={viewHeight}
    >
      <Heading size={'md'} pb={3} textTransform='uppercase' textAlign={'center'}>
        {viewTitle}
      </Heading>
      {children}
    </Box>
  );
};

export default ViewBox;
