import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSingleCampaign, selectCampaign } from './campaignSlice';
import SingleCampaignMailingCard from '../../components/SingleCampaign/SingleCampaignMailingCard';
import SingleCampaignScriptCard from '../../components/SingleCampaign/SingleCampaignScriptCard';

const SingleCampaign: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { entities: campaign } = useSelector(selectCampaign);

  useEffect(() => {
    if (!params.id) return;
    dispatch(fetchSingleCampaign(params.id!));
  }, [params, dispatch]);

  if (!campaign) {
    return (
      <Flex minH='100vh' w='100%' align='center' justify='center'>
        <Spinner size='xl' />
      </Flex>
    );
  }

  return (
    <Flex w={'100%'} h={'100%'} pr={8} justifyContent={'space-between'} gap={8}>
      <SingleCampaignMailingCard
        mailing={{
          calendarName: campaign.calendarName,
          anonymousUrl: campaign.anonymousUrl,
          id: campaign._id!,
          scriptId: campaign.script && campaign.script.callsDone > 0 ? campaign.script._id! : '',
        }}
        creations={{
          originalHtml: campaign.mailing,
          anonymousHtml: campaign.anonymousHtml || '',
          resultHtml: campaign.resultHtml || '',
          script: campaign.script && campaign.script.script ? campaign.script.script : '',
        }}
      />
      {campaign.script && (
        <SingleCampaignScriptCard
          scriptData={{
            startDate: campaign.script.start,
            bounceRate: campaign.script.bounceRate,
            clickToOpenRate: campaign.script.clickToOpenRate,
            conversionRate: campaign.script.conversionRate,
            activeScript: campaign.script.isActive,
            mobileScript: campaign.script.isMobile,
            desktopScript: campaign.script.isDesktop,
            allActions: campaign.script.allActions || 0,
            openActions: campaign.script.openActions || 0,
            fullActions: campaign.script.fullActions || 0,
            bounceActions: campaign.script.bounceActions || 0,
            callsDone: campaign.script.callsDone || 0,
            callsOpen: campaign.script.callsOpen || 0,
            callsFull: campaign.script.callsFull || 0,
            callsBounced: campaign.script.callsBounced || 0,
            callsFailed: campaign.script.callsFailed || 0,
            id: campaign.script._id!,
          }}
        />
      )}
    </Flex>
  );
};

export default SingleCampaign;
