import React from 'react';
import {
  Button,
  Modal,
  Flex,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spacer,
} from '@chakra-ui/react';

interface ModalProps {
  modalOnConfirm: () => void;
  modalActions: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

const ConfirmationModal: React.FC<ModalProps> = ({ modalOnConfirm, modalActions }: ModalProps) => {
  return (
    <Modal isOpen={modalActions.isOpen} onClose={modalActions.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Potwierdzenie</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Czy na pewno chcesz wykonać tę akcję?</ModalBody>
        <ModalFooter>
          <Flex w='100%'>
            <Button variant='ghost' colorScheme='red' mr={3} onClick={modalActions.onClose}>
              Odrzuć
            </Button>
            <Spacer />
            <Button variant='solid' colorScheme='green' onClick={modalOnConfirm}>
              Potwierdź
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
